import React from 'react';
import Modal, { useModalManager } from '@/components/common/Modal';
import Form from '@/components/shared/LessonOverview/Cards/ActionBar/Form';
import ISlideShow from '@/types/api/SlideShow';
import copyIcon from 'images/icons/copySlide.svg';
import I18n from 'i18n-js';
import ActionBarButton from '../ActionBarButton/ActionBarButton';

type CopyButtonType = {
  programId: number;
  lessonId: number;
  slideShow: ISlideShow;
  fromSlideShow?: boolean
};

const Copy = ({ programId, lessonId, slideShow, fromSlideShow } : CopyButtonType) => {
  const modalManager = useModalManager();

  return (
    <>
      <ActionBarButton
        tag="copy"
        icon={copyIcon}
        onClick={modalManager.open}
      />
      <Modal
        closeModal={modalManager.close}
        isOpen={modalManager.isOpen}
        headerText={I18n.t('save_new_presentation')}
      >
        <Form
          button="clone"
          id={slideShow.id}
          slideShowId={slideShow.slideShowId}
          title={slideShow.title}
          type={slideShow.className}
          useNewBuilder={slideShow.useNewBuilder}
          programId={programId}
          lessonId={lessonId}
          fromSlideShow={fromSlideShow}
        />
      </Modal>
    </>
  );
};

export default Copy;
