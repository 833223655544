import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { plato_api_image_models_path } from '@/modules/routes';
import IImageModel from '@/components/interfaces/ImageModel';
import IImage from '@/components/interfaces/Image';
import { useMemo } from 'react';

export type QuestionType =
  'OpenEndedQuestion' |
  'PromptOnly' |
  'MultipleChoiceQuestion' |
  'GridQuestion' |
  'FillInTheBlankQuestion' |
  'PageBreak' |
  'ImageMultipleChoiceQuestion' |
  'SliderQuestion' |
  'DrawingQuestion' |
  'DragAndDropQuestion';

export type QuestionOrQuestionGroupType = QuestionType | 'QuestionGroup';

export enum QueryKeys {
  ImageModels = 'IMAGE_MODELS',
  Delete = 'DELETE',
}

export interface ImageModelResponseType extends IImageModel {
  image: IImage;
}

interface ImageModelsApiResponse {
  data: ImageModelResponseType[];
}

export interface ImageModelOpts {
  forAnswerKey?: boolean;
  forGridQuestion?: boolean;
  nonBackgroundImages?: boolean;
  modelType?: 'Question' | 'QuestionGroup'
}

const DEFAULT_OPTS: ImageModelOpts = {
  forAnswerKey: false,
  forGridQuestion: false,
  nonBackgroundImages: false,
  modelType: 'Question',
};

export function useImageModels(modelId, opts: ImageModelOpts = DEFAULT_OPTS) {
  const options = { ...DEFAULT_OPTS, ...opts };

  return useQuery([QueryKeys.ImageModels, modelId, options], () => (
    axios
      .get<ImageModelsApiResponse>(plato_api_image_models_path({
      for_model: {
        model_id: modelId, model_type: options.modelType,
      },
      answer_images: options.forAnswerKey,
      non_answer_images: !(options.forAnswerKey || options.forGridQuestion),
      non_background_images: options.nonBackgroundImages,
      extra_attributes: ['dimensions'],
      includes: ['image'],
    }))
      .then(res => res.data.data)
      .catch(() => ([]))
  ), { placeholderData: [] });
}

export function useExcludedFields(userType: UserType) {
  return useMemo(() => {
    if (['Teacher'].includes(userType)) return ['zoom_type', 'alignment', 'caption_en', 'caption_es'];

    return [];
  }, [userType]);
}
