import React, { useState } from 'react';
import I18n from '@/modules/i18n';
import * as Routes from '@/modules/routes';
import Modal, { useModalManager } from '@/components/common/Modal';
import PaginatedTable, { usePaginatedTable } from '@/components/common/DataTable/PaginatedTable';
import AdvancedFilters from '@/components/common/AdvancedFilters';
import { buildFilterPath } from '@/components/common/AdvancedFilters/Utils';
import useSyncActivityTableColumns from './useSyncActivityTableColumns';
import LmsButton from './LmsButton';

interface ViewSyncActivityProps {
  assignmentId: number;
  showGoogleClassroomLogo: boolean;
  showCanvasLogo: boolean;
  showSchoologyLogo: boolean;
}

const PAGE_SIZE = 20;

const ViewSyncActivity = ({
  assignmentId, showGoogleClassroomLogo, showCanvasLogo, showSchoologyLogo,
}: ViewSyncActivityProps) => {
  const [getUrl, setGetUrl] = useState(
    Routes.plato_api_lms_sync_events_path({
      column: 'columnName',
      for_assignment: assignmentId,
      includes: ['student'],
      order: 'sortDirection',
      page: 'pageNumber',
      per_page: 20,
    }),
  );
  const modalManager = useModalManager();
  const [isExpanded, setIsExpanded] = useState(false);
  const { columns, columnMapping, columnDefs } = useSyncActivityTableColumns(isExpanded);
  const {
    resources,
    pagesLoaded,
    addResources,
    doneLoading,
    resetResources,
    updateActiveFilters,
  } = usePaginatedTable(PAGE_SIZE);

  return (
    <>
      <LmsButton
        handleClick={modalManager.open}
        showGoogleClassroomLogo={showGoogleClassroomLogo}
        showCanvasLogo={showCanvasLogo}
        showSchoologyLogo={showSchoologyLogo}
      >
        <i className="fa fa-lock" /> {I18n.t('view_sync_activity')}
      </LmsButton>
      <Modal
        headerText={`Sync Activity for Assignment ID ${assignmentId}`}
        isOpen={modalManager.isOpen}
        closeModal={() => {
          setGetUrl(buildFilterPath(
            Routes.plato_api_lms_sync_events_path({
              column: 'columnName',
              for_assignment: assignmentId,
              includes: ['student'],
              order: 'sortDirection',
              page: 'pageNumber',
              per_page: 20,
            }),
          ));
          updateActiveFilters({});
          modalManager.close();
        }}
        nearlyFullScreen
      >
        <AdvancedFilters
          asyncSearchPath={Routes.plato_api_search_lms_sync_events_path({ for_assignment: assignmentId })}
          properties={[
            { isDate: true, label: 'Date', value: 'created_at', operators: ['eq', 'not_eq', 'gteq', 'lteq'] },
            {
              label: 'First Name',
              value: 'student_first_name',
              operators: ['eq', 'not_eq', 'cont', 'not_cont'],
            },
            {
              label: 'Last Name',
              value: 'student_last_name',
              operators: ['eq', 'not_eq', 'cont', 'not_cont'],
            },
            {
              label: 'Username',
              value: 'student_username',
              operators: ['eq', 'not_eq', 'cont', 'not_cont'],
            },
            { isNumerical: true, label: 'HTTP Status', value: 'http_status', operators: ['eq', 'not_eq'] },
            { label: 'Category', value: 'category', operators: ['eq', 'not_eq', 'cont', 'not_cont'] },
          ]}
          resources={resources}
          updateActiveFilters={(filters: any) => {
            setGetUrl(buildFilterPath(
              Routes.plato_api_lms_sync_events_path({
                column: 'columnName',
                for_assignment: assignmentId,
                includes: ['student'],
                order: 'sortDirection',
                page: 'pageNumber',
                per_page: 20,
              }),
              filters,
            ));
            updateActiveFilters(filters);
          }}
        />
        <PaginatedTable
          autoWidth={false}
          buttons={[
            {
              action: () => setIsExpanded(prev => !prev),
              className: 'btn btn--sm btn--outline-purple mr30',
              enabled: true,
              text: isExpanded ? 'Collapse All' : 'Expand All',
            },
          ]}
          key={isExpanded}
          columns={columns}
          columnDefs={columnDefs}
          columnMapping={columnMapping}
          exportedColumns={[0, 1, 2, 3, 4]}
          defaultOrder={[0, 'desc']}
          getUrl={getUrl}
          pageSize={PAGE_SIZE}
          pagesLoaded={pagesLoaded}
          doneLoading={doneLoading}
          resources={resources}
          addResources={addResources}
          resetResources={resetResources}
        />
      </Modal>
    </>
  );
};

export default ViewSyncActivity;
