import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import I18n from 'i18n-js';
import * as Routes from '@/modules/routes';
import styles from '@/components/shared/LessonOverview/Cards/Cards.module.scss';
import { Lesson, Program, SectionType, StafferSlideShows, StudentSlideShows } from '@/types/api/LessonOverview';
import SlideTypeToggle from './Staffer/SlideTypeToggle';
import StafferFlexibleLessonOptions from './Staffer/FlexibleLessonOptions';
import StudentFlexibleLessonOptions from './Student/FlexibleLessonOptions';
import TeacherSlideShows from './Staffer/TeacherSlideShows';
import lessonOverviewStyles from './Cards/index.module.scss';
import LessonSubheader from './LessonSubheader';
import DiveDeeper from './DiveDeeper';
import AssessWith from './AssessWith';

interface LessonOverviewProps {
  hasAssessments?: boolean;
  hideTabs?: boolean;
  isStaffer: boolean;
  lessonId?: string;
  locale?: string;
  programId?: string;
  stafferId?: number;
}

type APIResponse = {
  lesson: Lesson;
  program: Program;
  sections: SectionType[];
  slideShows: StafferSlideShows | StudentSlideShows;
  teacherInitials: string;
};

const LessonOverview = ({
  hasAssessments = true,
  hideTabs = false,
  isStaffer,
  lessonId = null,
  locale = null,
  programId = null,
  stafferId = null,
}: LessonOverviewProps) => {
  const [selected, setSelected] = useState<string | number>('resource-0');
  const [showArchived, setShowArchived] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lesson, setLesson] = useState(null);
  const [program, setProgram] = useState(null);
  const [sections, setSections] = useState([]);
  const [slideShows, setSlideShows] = useState(null);
  const showTeacherLessonGuides = selected !== 'resource-0';
  const FlexibleLessonOptions = isStaffer ? StafferFlexibleLessonOptions : StudentFlexibleLessonOptions;

  const ajaxPath = Routes.plato_api_program_lesson_overview_index_path(
    programId, lessonId, { locale },
  );

  const getSlideShows = () => {
    Axios.get<APIResponse>(ajaxPath)
      .then((response) => {
        setLesson(response.data.lesson);
        setProgram(response.data.program);
        setSections(response.data.sections);
        setSlideShows(response.data.slideShows);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSlideShows();
  }, []);

  if (isLoading) {
    return (
      <div className="mt20">
        <i className="fa fa-spinner fa-spin" aria-hidden="true" />
        <span className="ml5">Lesson guides are currently loading. Please wait...</span>
      </div>
    );
  }

  const hasArchivedContent = () => (
    slideShows?.tci?.first?.archived
      || slideShows?.tci?.oldSlideShows?.some(slideShow => slideShow.archived)
      || slideShows?.tci?.newSlideShows?.some(slideShow => slideShow.archived)
  );

  return (
    <div>
      {!hideTabs && stafferId && (
        <SlideTypeToggle
          hasArchivedContent={hasArchivedContent()}
          hideTabs={hideTabs}
          setSelected={setSelected}
          toggleShowArchived={() => setShowArchived(prev => !prev)}
        />
      )}
      <div className={styles.cards}>
        {showTeacherLessonGuides && stafferId && (
          <TeacherSlideShows
            hideTabs={hideTabs}
            lessonId={lesson.id}
            programId={program.id}
            slideShows={slideShows}
            stafferId={stafferId}
          />
        )}
        {!showTeacherLessonGuides && (
          <div className={lessonOverviewStyles.lessonOverviewWrapper} id="tciContentContainer">
            <LessonSubheader
              title={I18n.t('flexible_lesson_options')}
              description={I18n.t('lesson_subheader_flexible_lesson_options')}
              isStaffer={isStaffer}
            >
              <FlexibleLessonOptions
                hideTabs={hideTabs}
                lesson={lesson}
                program={program}
                sections={sections}
                showArchived={showArchived}
                slideShows={slideShows}
                stafferId={stafferId}
              />
            </LessonSubheader>
            <DiveDeeper
              lesson={lesson}
              program={program}
              sections={sections}
              stafferId={stafferId}
            />
            <AssessWith
              hasAssessments={hasAssessments}
              lesson={lesson}
              program={program}
              sections={sections}
              stafferId={stafferId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LessonOverview;
