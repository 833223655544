import { useEffect, useState } from 'react';
import axios from 'axios';
import * as Routes from '@/modules/routes';
import { StafferSlideShows } from '@/types/api/LessonOverview';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';

type UseLessonOverviewProps = {
  programId: number,
  lessonId: number,
  locale: string,
};

const useLessonOverview = ({ programId, lessonId, locale } : UseLessonOverviewProps) => {
  const [lesson, setLesson] = useState(null);
  const [sections, setSections] = useState([]);
  const [slideShows, setSlideShows] = useState<StafferSlideShows>(null);
  const [isLoading, setIsLoading] = useState(true);
  const program = useLessonOverviewContext(state => state.program);
  const setProgram = useLessonOverviewContext(state => state.setProgram);

  const lessonOverviewPath = Routes.plato_api_program_lesson_overview_index_path(
    programId, lessonId, { locale },
  );

  useEffect(() => {
    axios.get(lessonOverviewPath)
      .then((response) => {
        setLesson(response.data.lesson);
        setProgram(response.data.program);
        setSections(response.data.sections);
        setSlideShows(response.data.slideShows);
      }).finally(() => setIsLoading(false));
  }, []);

  return { lesson, program, sections, slideShows, isLoading };
};

export default useLessonOverview;
