import { createStore } from 'zustand';
import { Program } from '@/types/api/LessonOverview';
import { CreateLessonOverviewStoreVariables, NewLessonOverviewProps } from './types';

const createLessonOverviewStore = (
  props: NewLessonOverviewProps,
  storeRef: ReturnType<typeof createLessonOverviewStore>,
) => createStore<CreateLessonOverviewStoreVariables>()((set, get) => ({
  civicsLibrary: props.civicsLibrary ?? false,
  hasAssessments: props.hasAssessments,
  lessonId: props.lessonId,
  locale: props.locale,
  primarySourceLibrary: props.primarySourceLibrary ?? false,
  programId: props.programId,
  stafferId: props.stafferId,
  storeRef,
  unitColor: props.unitColor,
  unitId: props.unitId,
  program: null,
  setProgram: (program: Program) => set({ program }),
}));

export default createLessonOverviewStore;
