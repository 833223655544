import React from 'react';
import { Lesson, Program, StudentSlideShows } from '@/types/api/LessonOverview';
import ActivityCard from '@/components/shared/LessonOverview/Cards/ActivityCard';
import TeacherCard from '@/components/shared/LessonOverview/Staffer/TeacherCard';

interface TCISlideShowsProps {
  lesson: Lesson;
  program: Program;
  slideShows: StudentSlideShows;
}

// Method for rendering legacy "Shared" slideshow cards from before we were able to assign them.
// Cleanup/remove once TCI slideshows have been refactored to assign the slideshow instead of notebooks.
// Don't show previously shared slideshows if they have been assigned.
const TCISlideShows = ({ slideShows, lesson, program }: TCISlideShowsProps) => {
  const tciSlideShows = slideShows.tci.filter(slideShow => !slideShow.isAssigned);

  return (
    <>
      {tciSlideShows.map((slideShow) => {
        const CardComponent = slideShow.activityType ? ActivityCard : TeacherCard;

        return (
          <CardComponent
            key={slideShow.id}
            slideShow={slideShow}
            programId={program.id}
            lessonId={lesson.id}
          />
        );
      })}
    </>
  );
};

export default TCISlideShows;
