import React from 'react';
import I18n from 'modules/i18n';
import { Program, SectionType } from '@/types/api/LessonOverview';
import videoActivityIcon from 'images/icons/video-activity-green-outline.svg';
import ButtonWithTooltip from '@/components/shared/LessonOverview/Cards/ActionBar/ButtonWithTooltip';
import Card, { ActionBar, CardBody } from './Card';
import AssignButton from './AssignButton';
import * as Routes from '../../../../modules/routes';

type SectionVideoCardProps = {
  lessonId: number;
  program: Program;
  readingPath: string;
  section: SectionType;
  stafferId?: number;
};

const SectionVideoCard = ({
  lessonId, program, readingPath, section, stafferId,
}: SectionVideoCardProps) => {
  const renderActionBar = () => (
    <ActionBar>
      <AssignButton
        initialValues={{
          modelType: 'sectionNotebook',
          modelsToAssign: [section.assignable.value],
        }}
        isAssigned={section.isAssigned}
        lessonId={lessonId}
        program={program}
        stafferId={stafferId}
      />
      <ButtonWithTooltip
        ariaLabel="Print reading"
        link={Routes.pdf_shared_program_lesson_path(program.id, lessonId, {
          locale: I18n.locale,
        })}
        openInNewWindow
        tooltipContent={I18n.t('print_text')}
        iconClassName="fa fa-print"
      />
    </ActionBar>
  );

  return (
    <Card bannerBorderColor="green" label="video_activity" type={section.kind}>
      <CardBody
        ariaLabel={section.titleWithoutPageNumbers}
        iconSrc={videoActivityIcon}
        linkHref={readingPath}
        linkLabel={section.titleWithoutPageNumbers}
      >
        {I18n.t('lesson_video_description')}
      </CardBody>

      {stafferId && renderActionBar()}
    </Card>
  );
};

export default SectionVideoCard;
