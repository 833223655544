import { plato_api_classroom_lessons_path, plato_api_classroom_lesson_path } from '@/modules/routes';
import { IClassroom, IClassroomLesson, ILesson } from '../Scores/GradesTable/types';
import { GradeByType } from './types';

export const useSubmit = (classroomLesson: IClassroomLesson) => {
  if (classroomLesson) return { method: 'patch', path: plato_api_classroom_lesson_path(classroomLesson.id) };

  return { method: 'post', path: plato_api_classroom_lessons_path() };
};

type UseAdditionalDataArgs = {
  classroom?: IClassroom;
  classroomLesson: IClassroomLesson;
  gradingBy: GradeByType;
  lesson: ILesson;
};

export const useAdditionalData = ({
  classroom,
  classroomLesson,
  gradingBy,
  lesson,
}: UseAdditionalDataArgs) => {
  if (classroomLesson) {
    return ({
      'classroom_lesson[grading_notebook_by]': gradingBy,
    });
  }

  return ({
    'classroom_lesson[classroom_id]': classroom.id,
    'classroom_lesson[grading_notebook_by]': gradingBy,
    'classroom_lesson[lesson_id]': lesson.id,
  });
};
