import React, { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import useFlags from '@/hooks/useFlags';
import LessonOverviewDisplayLocationField from './LessonOverviewDisplayLocationField';
import { TextField, CheckboxField } from '../../../../common/Forms';
import styles from './CreateTransitionGroup.module.scss';
import { SetCurrentAction, Slide, SlideGroup, CREATING_SLIDE_GROUPS } from './utils';
import SelectSlideTable from './SelectSlideTable';
import { Footer } from '../../../../common/Modal';
import IconSelect from './IconSelect';

type EditSlideGroupFormProps = {
  slideGroup: SlideGroup;
  slides: Slide[];
  onSubmit: (any) => void;
  setCurrentAction: SetCurrentAction;
};

const EditSlideGroupForm = ({ slideGroup, slides, onSubmit, setCurrentAction }: EditSlideGroupFormProps) => {
  const slideIds = slideGroup.slides.map(slide => parseInt(String(slide.id), 10));
  const [selectedSlideIds, setSelectedSlideIds] = useState(slideIds);
  const { displaySlideGroupHeaders, newLessonOverviewPage } = useFlags();

  const slidesForTable = useMemo(() => (
    slides.filter(slide => slide.type !== 'Transition')
  ), [slides]);

  return (
    <Form
      initialValues={{
        title_en: slideGroup.titleEn,
        title_es: slideGroup.titleEs,
        extension: slideGroup.extension,
        pacing: slideGroup.pacing,
        display_slide_header: slideGroup.displaySlideHeader,
        icon: slideGroup.icon,
        display_location_in_lesson_overview: slideGroup.displayLocationInLessonOverview,
      }}
      onSubmit={onSubmit}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ handleSubmit, form, values }) => (
        <form className={styles.form} onSubmit={handleSubmit}>
          <TextField
            name="title_en"
            placeholder="Enter a title here…"
            label="Title (English)"
            required
          />

          <TextField
            name="title_es"
            placeholder="Enter a title here…"
            label="Title (Spanish)"
            tooltipText="A Spanish translation will be automatically entered if there is none provided."
          />

          <div className="tw-flex tw-w-3/12 tw-justify-between">
            <TextField
              className={styles.numberInput}
              name="pacing"
              placeholder="Enter time..."
              label="Pacing Time (minutes)"
            />

            <CheckboxField
              name="extension"
              label="Extension Activity"
              tooltipText="Enabling this will label this activity as an extension in the transition slides."
              labelClass="tw-mr-2 tw-font-bold"
            />
          </div>

          {newLessonOverviewPage && (
            <div className="tw-w-5/12 tw-flex tw-flex-shrink-0 tw-h-14">
              <LessonOverviewDisplayLocationField values={values} setFormValue={form.mutators.setValue} />
            </div>
          )}

          {displaySlideGroupHeaders && (
            <div className="tw-flex tw-w-2/5">
              <div className="tw-flex tw-w-3/12 tw-justify-between">
                <CheckboxField
                  name="display_slide_header"
                  label="Display Slide Header"
                  tooltipText="Enabling this will display the Slide Group title on each slide in this group."
                  labelClass="tw-mr-2 tw-font-bold"
                />
              </div>

              {values.display_slide_header && (
                <div className="tw-flex tw-w-3/12">
                  <IconSelect
                    currentIcon={slideGroup.icon}
                    setIcon={form.mutators.setValue}
                  />
                </div>
              )}
            </div>
          )}

          <Field
            className={styles.input}
            id="slideIds"
            name="slideIds"
            component="input"
            placeholder="Slide Ids"
            type="hidden"
          />

          <div className={styles.table}>
            <SelectSlideTable
              slides={slidesForTable}
              selectedSlideIds={selectedSlideIds}
              setSelectedSlideIds={setSelectedSlideIds}
              setValue={form.mutators.setValue}
            />
          </div>

          <Footer
            nearlyFullScreen
            secondaryButtonText="Back"
            secondaryButtonCallback={() => {
              setCurrentAction(CREATING_SLIDE_GROUPS);
            }}
            wrapperClassName="w100"
          />
        </form>
      )}
    />
  );
};

export default EditSlideGroupForm;
