import React from 'react';
import { JSONTree } from 'react-json-tree';
import ReactDOM from 'react-dom';
import { getDateTime, capitalizeWords } from '@/modules/TCIUtils';

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#000000',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

const jsonCell = (td, cellData, isExpanded) => {
  let value = JSON.parse(cellData.includes('{') ? cellData : '{}');

  try {
    while (typeof value === 'string') {
      value = value.replaceAll('=>', ':').replaceAll('nil', 'null');
      value = JSON.parse(value);
    }
  } catch {
    value = JSON.parse(cellData.includes('{') ? cellData : '{}');
  }

  ReactDOM.render(
    <JSONTree
      data={value}
      shouldExpandNodeInitially={() => isExpanded}
      theme={theme}
      invertTheme
    />,
    td,
  );
};

const formatCategory = (data) => {
  if (!data) return '';

  return (
    capitalizeWords(data.replace('_', ' '))
  );
};

const useSyncActivityTableColumns = (isExpanded: boolean) => {
  const columnDefs = [
    {
      name: 'created_at',
      render: data => getDateTime(data),
      targets: 0,
      title: 'Date',
      type: 'date',
      width: '10%',
    },
    {
      name: 'student',
      targets: 1,
      width: '15%',
    },
    {
      className: 'tw-break-all',
      name: 'endpoint',
      targets: 2,
      width: '15%',
    },
    {
      className: 'tw-break-all',
      name: 'category',
      targets: 3,
      width: '10%',
    },
    {
      className: 'tw-break-all',
      createdCell: (td, cellData) => jsonCell(td, cellData, isExpanded),
      name: 'params',
      sortable: false,
      targets: 4,
      width: '25%',
    },
    {
      className: 'tw-break-all',
      createdCell: (td, cellData) => jsonCell(td, cellData, isExpanded),
      name: 'raw_response',
      sortable: false,
      targets: 5,
      title: 'Response',
      width: '35%',
    },
    {
      name: 'http_status',
      sortable: false,
      title: 'HTTP Status',
      targets: 6,
    },
  ];
  const columns = columnDefs.map(c => c.name);
  const columnMapping = columnDefs.map(c => (c.name === 'actions' ? null : c.name));

  return { columns, columnDefs, columnMapping };
};

export default useSyncActivityTableColumns;
