import I18n from 'i18n-js';
import React from 'react';

interface CardTitleProps {
  type?: string;
  linkHref: string;
  linkLabel: string;
  target: '_blank' | '';
  page?: string;
  color: string;
  time?: string;
}

export const CardTitle = ({
  type,
  linkHref,
  linkLabel,
  target,
  page,
  color,
  time,
}: CardTitleProps) => (
  <div className="tw-h-20 tw-pl-4">
    {type && (
      <span className="tw-text-very-dark-grey tw-text-lg tw-leading-6">
        {I18n.t(type)}
      </span>
    )}
    <div className="tw-text-tci-purple tw-text-xl tw-font-bold tw-leading-6 tw-mb-4 tw-tracking-wide">
      <a
        href={linkHref}
        className="hover:tw-text-very-dark-grey"
        aria-label={linkLabel}
        target={target}
      >
        {linkLabel}
      </a>
    </div>
    <div className="tw-flex">
      {page && (
        <p className="tw-mr-8">
          <span
            style={{ color: `#${color}` }}
            className="tw-mr-1 tw-font-bold"
          >
            {I18n.t('page')}
          </span>
          <span className="tw-text-very-dark-grey">
            {page || '-'}
          </span>
        </p>
      )}
      {time && (
        <p>
          <span
            style={{ color: `#${color}` }}
            className="tw-mr-1 tw-font-bold"
          >
            {I18n.t('time')}
          </span>
          <span className="tw-text-very-dark-grey">
            {time || '-'}
          </span>
        </p>
      )}
    </div>
  </div>
);

export default CardTitle;
