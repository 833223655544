import React, { useState } from 'react';
import I18n from '@/modules/i18n';
import { SubmitModal, useModalManager } from '@/components/common/Modal';
import { capitalize } from '@/components/common/Utils';
import { GradeByType, GradingBy } from './types';
import { GradeByMenu } from '../GradeByMenu';
import SwitchGradeModeConfirmationText from './SwitchGradeModeConfirmationText';
import { IClassroom, IClassroomLesson, ILesson } from '../Scores/GradesTable/types';
import { useAdditionalData, useSubmit } from './utils';

interface NotebookGradeByMenuProps {
  classroom: IClassroom;
  classroomLesson?: IClassroomLesson;
  lesson: ILesson;
}

const NotebookGradeByMenu = ({
  classroom,
  classroomLesson,
  lesson,
}: NotebookGradeByMenuProps) => {
  const [gradingBy, setGradingBy] = useState(classroomLesson?.grading_notebook_by || GradeByType.Section);
  const [reloading, setReloading] = useState(false);
  const modalManager = useModalManager();
  const { method, path: submitPath } = useSubmit(classroomLesson);
  const additionalData = useAdditionalData({ classroom, classroomLesson, gradingBy, lesson });

  const handleSelect = (value: GradingBy) => {
    setGradingBy(value);
    modalManager.open();
  };

  const afterSubmit = () => {
    setReloading(true);
    window.location.reload();
  };

  return (
    <>
      <GradeByMenu handleSelect={handleSelect} />

      <SubmitModal
        additionalData={additionalData}
        afterSubmit={afterSubmit}
        closeAfterSubmit
        closeModal={modalManager.close}
        headerText={`${I18n.t('grade_by')} ${capitalize(I18n.t(gradingBy))}`}
        isOpen={modalManager.isOpen}
        method={method}
        submitting={reloading}
        submitPath={submitPath}
      >
        <SwitchGradeModeConfirmationText
          gradingBy={gradingBy}
          lessonTitle={lesson.title}
        />
      </SubmitModal>
    </>
  );
};

export default NotebookGradeByMenu;
