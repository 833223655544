import React from 'react';
import { IIconProps } from './utils';

export const Print = ({ fill = 'currentColor' }: IIconProps) => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill}
      d="M4.2171 12.9718H11.8775V10.7831H4.2171V12.9718ZM4.2171 7.50012H11.8775V4.2171H10.5096C10.2816 4.2171 10.0878 4.13731 9.92819 3.97771C9.76859 3.81812 9.6888 3.62433 9.6888 3.39635V2.02842H4.2171V7.50012ZM14.0662 8.04729C14.0662 7.8991 14.012 7.77085 13.9037 7.66256C13.7954 7.55427 13.6672 7.50012 13.519 7.50012C13.3708 7.50012 13.2426 7.55427 13.1343 7.66256C13.026 7.77085 12.9718 7.8991 12.9718 8.04729C12.9718 8.19548 13.026 8.32372 13.1343 8.43202C13.2426 8.54031 13.3708 8.59446 13.519 8.59446C13.6672 8.59446 13.7954 8.54031 13.9037 8.43202C14.012 8.32372 14.0662 8.19548 14.0662 8.04729ZM15.1605 8.04729V11.6039C15.1605 11.678 15.1334 11.7421 15.0793 11.7963C15.0251 11.8504 14.961 11.8775 14.8869 11.8775H12.9718V13.2454C12.9718 13.4734 12.892 13.6672 12.7324 13.8268C12.5728 13.9864 12.3791 14.0662 12.1511 14.0662H3.94352C3.71553 14.0662 3.52174 13.9864 3.36215 13.8268C3.20256 13.6672 3.12276 13.4734 3.12276 13.2454V11.8775H1.20767C1.13357 11.8775 1.06945 11.8504 1.0153 11.7963C0.961156 11.7421 0.934082 11.678 0.934082 11.6039V8.04729C0.934082 7.59701 1.0951 7.21086 1.41713 6.88883C1.73916 6.5668 2.12532 6.40578 2.57559 6.40578H3.12276V1.75484C3.12276 1.52685 3.20256 1.33306 3.36215 1.17347C3.52174 1.01388 3.71553 0.934082 3.94352 0.934082H9.6888C9.91679 0.934082 10.1676 0.991079 10.4412 1.10507C10.7147 1.21907 10.9313 1.35586 11.0909 1.51545L12.3904 2.81498C12.55 2.97457 12.6868 3.19116 12.8008 3.46474C12.9148 3.73833 12.9718 3.98911 12.9718 4.2171V6.40578H13.519C13.9693 6.40578 14.3554 6.5668 14.6774 6.88883C14.9995 7.21086 15.1605 7.59701 15.1605 8.04729Z"
    />
  </svg>
);
