import React from 'react';
import clsx from 'clsx';
import { InitialAssignableType } from '@/components/shared/LessonOverview/Cards/AssignButton';
import { Program } from '@/types/api/LessonOverview';
import AssignButton from './AssignButton';
import GoToButton from './GoToButton';

interface ActionBarProps {
  border?: boolean;
  children?: ReactChildren;
  href: string;
  iconType: 'play' | 'arrow';
  initialValues?: InitialAssignableType;
  isAssigned?: boolean;
  label?: string;
  lessonId: number;
  program: Program;
  stafferId?: number;
}

const ActionBar = ({
  border = true,
  children,
  href,
  iconType,
  initialValues,
  isAssigned,
  label,
  lessonId,
  program,
  stafferId,
}: ActionBarProps) => (
  <div
    className={clsx('tw-py-2.5 tw-px-4 tw-mx-2', {
      'tw-border-t tw-border-solid tw-border-med-grey': border,
    })}
  >
    <div className="tw-h-auto tw-text-center tw-leading-[30px] tw-flex tw-items-center">
      <GoToButton label={label} iconType={iconType} href={href} />
      <AssignButton
        circle
        initialValues={initialValues}
        isAssigned={isAssigned}
        lessonId={lessonId}
        program={program}
        stafferId={stafferId}
      />
      {children}
    </div>
  </div>
);

export default ActionBar;
