import React from 'react';
import I18n from 'i18n-js';
import useLessonOverview from '@/hooks/api/useLessonOverview';
import Header from '@/components/shared/NewLessonOverview/Header';
import AssessWith from '@/components/shared/NewLessonOverview/AssessWith';
import useLessonOverviewContext from '@/components/shared/NewLessonOverview/useLessonOverviewContext';
import NewLessonOverviewStoreProvider from '@/components/shared/NewLessonOverview/NewLessonOverviewStoreProvider';
import { NewLessonOverviewProps } from './types';
import DiveDeeper from './DiveDeeper';
import Libraries from './Libraries';
import LessonOptions from './LessonOptions';
import LessonSubheader from './LessonSubheader';
import SlideGroupCard from './Cards/Card/SlideGroupCard';

const NewLessonOverview = () => {
  const hasAssessments = useLessonOverviewContext(state => state.hasAssessments);
  const lessonId = useLessonOverviewContext(state => state.lessonId);
  const locale = useLessonOverviewContext(state => state.locale);
  const programId = useLessonOverviewContext(state => state.programId);
  const stafferId = useLessonOverviewContext(state => state.stafferId);
  const unitColor = useLessonOverviewContext(state => state.unitColor);
  const unitId = useLessonOverviewContext(state => state.unitId);
  const civicsLibrary = useLessonOverviewContext(state => state.civicsLibrary);
  const primarySourceLibrary = useLessonOverviewContext(state => state.primarySourceLibrary);

  const {
    lesson,
    slideShows,
    program,
    sections,
    isLoading,
  } = useLessonOverview({ programId, lessonId, locale });

  if (isLoading) return null;

  const isStaffer = !!stafferId;
  const introSlideGroups = (slideShows?.tci?.first?.slideGroups ?? []).filter(sg => (
    sg.displayLocationInLessonOverview === 'introduce_the_lesson'
  ));
  const finalSlideGroups = (slideShows?.tci?.first?.slideGroups ?? []).filter(sg => (
    sg.displayLocationInLessonOverview === 'put_it_all_together'
  ));

  return (
    <>
      <Header
        lessonTitle={lesson?.title}
        lessonPosition={lesson?.titleNumber}
        unitColor={unitColor}
      />

      {introSlideGroups.length > 0 && (
        <LessonSubheader title={I18n.t('introduce_the_lesson')}>
          <section className="tw-w-full tw-pr-16">
            {introSlideGroups.map(slideGroup => (
              <SlideGroupCard
                slideGroup={slideGroup}
                icon="eye"
              />
            ))}
          </section>
        </LessonSubheader>
      )}

      {slideShows && (
        <LessonOptions
          isStaffer={isStaffer}
          program={program}
          slideShows={slideShows}
          unitColor={unitColor}
        />
      )}
      <div>
        {program && (
          <AssessWith
            hasAssessments={hasAssessments}
            lesson={lesson}
            program={program}
            sections={sections}
            stafferId={stafferId}
            unitColor={unitColor}
          />
        )}
      </div>

      {finalSlideGroups.length > 0 && (
        <LessonSubheader title={I18n.t('put_it_all_together')}>
          <section className="tw-w-full tw-pr-16">
            {finalSlideGroups.map(slideGroup => (
              <SlideGroupCard slideGroup={slideGroup} icon="pencil" />
            ))}
          </section>
        </LessonSubheader>
      )}

      <footer className="tw-w-full tw-h-[300px] tw-bg-light-grey tw-flex">
        <DiveDeeper
          lesson={lesson}
          stafferId={stafferId}
          programId={programId}
          sections={sections}
        />
        <Libraries
          programId={programId}
          lessonId={lessonId}
          unitId={unitId}
          civicsLibrary={civicsLibrary}
          primarySource={primarySourceLibrary}
        />
      </footer>
    </>
  );
};

const NewLessonOverviewWrapper = (props: NewLessonOverviewProps) => (
  <NewLessonOverviewStoreProvider props={props}>
    <NewLessonOverview />
  </NewLessonOverviewStoreProvider>
);

export default NewLessonOverviewWrapper;
