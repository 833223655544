import React from 'react';

type LessonSubheaderProps = {
  children: ReactChildren,
  description?: string,
  title: string,
};

const LessonSubheader = ({
  children, title,
}: LessonSubheaderProps) => !!React.Children.count(children) && (
  <div className="tw-ml-8 tw-mb-8 tw-relative">
    <h2 className="tw-my-2 tw-text-2xl">
      <span>{title}</span>
    </h2>
    <div className="tw-flex tw-flex-wrap tw-w-full">
      {children}
    </div>
  </div>
);

export default LessonSubheader;
