import React, { useRef, useState } from 'react';
import axios from 'axios';
import Autosize from 'react-input-autosize';
import { QueryClientProvider, useMutation } from '@tanstack/react-query';
import I18n from '@/modules/i18n';
import { queryClient } from '@/utils/ReactQuery';
import styles from './ScoreEdit.module.scss';
import { plato_api_submission_path } from '@/modules/routes';
import { formatLocalTimestamp } from '@/components/common/Utils';
import SaveStatus from '../SaveStatus';
import cn from '@/utils/cn';

interface ISubmission {
  assignment_id: number;
  graded_at: string;
  id: number;
  lms_synced_at?: string;
  lms_type?: string;
  score_updated_at?: string;
  sync_with_lms: boolean;
  total_score?: number;
  total_score_override?: number;
  user_id: number;
  user_type: 'Student' | 'Teacher';
  updated_at: string;
  created_at: string;
};

interface SubmissionScoreEditProps {
  assignment: {
    dueDate: string;
    id: number;
    lessonTitle: string;
    name: string;
    maxScore: string;
  };
  submission: ISubmission;
}

const SubmissionScoreEditComponent = ({ assignment, submission: initialSubmission }: SubmissionScoreEditProps) => {
  const [submission, setSubmission] = useState(initialSubmission);
  const { isLoading, isSuccess, isError, mutate } = useMutation({
    mutationFn: ({ score }: { score: number }) => (
      axios
        .put<{ data: ISubmission }>(plato_api_submission_path(submission.id), { total_score_override: score })
        .then((res) => {
          setSubmission(res.data.data);
        })
    ),
    mutationKey: ['submissionUpdate', submission.id],
  });

  const [totalScoreOverride, setTotalScoreOverride] = useState(
    submission.total_score_override || submission.total_score || 0
  );

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const updateTotalScoreOverride = (nextTotalScoreOverride: number) => {
    setTotalScoreOverride(nextTotalScoreOverride);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => mutate({ score: nextTotalScoreOverride }), 1500);
  };

  return (
    <div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <label className="tw-font-bold" htmlFor="submission[total_score_override]">{I18n.t('assignment_score')}:</label>
        <div>
          <Autosize
            className={styles.scoreInput}
            name="submission[total_score_override]"
            value={totalScoreOverride}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const next = parseInt(e.target.value, 10);

              if (Number.isNaN(next)) {
                updateTotalScoreOverride(0);
                return;
              }

              if (totalScoreOverride === next) return;

              updateTotalScoreOverride(next);
            }}
          />
          <span className="tw-ml-2">/ {assignment.maxScore}</span>
        </div>
      </div>
      <div className={cn(styles.saveStatus, 'tw-text-right !tw-mt-2')}>
        <SaveStatus
          lastUpdated={formatLocalTimestamp(submission.score_updated_at || submission.updated_at, false)}
          savedTime={isSuccess ? formatLocalTimestamp(submission.score_updated_at, false) : null}
          saving={isLoading}
          saveError={isError}
        />
      </div>
    </div>
  );
};

export const SubmissionScoreEdit = (props: SubmissionScoreEditProps) => (
  <QueryClientProvider client={queryClient}>
    <SubmissionScoreEditComponent {...props} />
  </QueryClientProvider>
);
