import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import UiCard from '@/components/ui/Card';
import { ImageModelResponseType, QuestionOrQuestionGroupType } from '@/components/admin/QuestionImage/utils';
import Edit from '@/components/admin/QuestionImage/Edit';
import Delete from '@/components/admin/QuestionImage/Delete';
import DisplayLocationDropdown from '@/components/admin/QuestionImage/DisplayLocationDropdown';
import useQuestionLocaleToggleListener from '@/hooks/useQuestionLocaleToggleListener';

interface CardProps {
  afterSubmit: (imageModel?: ImageModelResponseType[] | any) => any;
  afterDelete: (imageModelId?: number) => void;
  forAnswerKey?: boolean;
  imageModel: ImageModelResponseType;
  mediaType: string;
  modelId: number;
  modelType: 'Question' | 'QuestionGroup';
  questionType: QuestionOrQuestionGroupType;
  userType: UserType;
}

const Card = ({
  afterSubmit,
  afterDelete,
  forAnswerKey,
  imageModel,
  mediaType,
  modelId,
  modelType,
  questionType,
  userType,
}: CardProps) => {
  const { image } = imageModel;
  const hasAlignmentDropdown = questionType === 'DrawingQuestion';
  const inTable = imageModel?.media_meta?.type === 'in_grid';

  const { isInSpanish } = useQuestionLocaleToggleListener({ modelId, forAnswerKey, modelType });

  const url = useMemo(() => {
    if (isInSpanish && image?.es_url) return image.es_url;

    return image.url;
  }, [image?.url, image?.es_url, isInSpanish]);

  const isGif = image.image_en_file_name.toLowerCase().endsWith('.gif');

  return (
    <UiCard className="tw-w-[48%] tw-border tw-border-solid tw-border-[#ccc]
                       tw-min-h-[176px] tw-h-[176px] tw-grid tw-grid-cols-2 tw-overflow-visible"
    >
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div
          className="tw-max-w-[167px] tw-box-border tw-h-full tw-bg-no-repeat tw-bg-center
                    tw-bg-contain tw-border-r tw-border-r-[#ccc] tw-border-solid !tw-cursor-zoom-in"
          style={{ backgroundImage: `url(${url})` }}
        />
      </a>
      <div className="tw-m-2.5 tw-ml-0 tw-relative tw-flex tw-flex-col tw-justify-end tw-items-start">
        <i aria-hidden="true" className="fa fa-file-image-o tw-absolute tw-top-0 tw-right-0" />
        {hasAlignmentDropdown && (
          <div className="tw-pb-1">
            <DisplayLocationDropdown afterSubmit={afterSubmit} imageModel={imageModel} />
          </div>
        )}
        {inTable && (
          <div>
            <p>
              <span className="tw-font-bold">Display Location:</span>
              <br />Table
            </p>
          </div>
        )}
        <p>
          <span className="tw-font-bold">Dimensions:</span>
          {' '}
          {imageModel.dimensions}
        </p>
        <div className="tw-flex tw-justify-between tw-items-start tw-w-full">
          <Edit
            afterSubmit={afterSubmit}
            imageModel={imageModel}
            modelId={modelId}
            modelType={modelType}
            userType={userType}
            mediaType={imageModel.media_meta.type || mediaType}
          />
          <Delete afterDelete={afterDelete} imageModel={imageModel} />
        </div>
        {isGif && (
          <p className="tw-m-0">
            <i aria-hidden="true" className="fa fa-exclamation-circle" />
            {' '}
            GIFs will not show up in print and can only be accessed online
          </p>
        )}
      </div>
    </UiCard>
  );
};

export default Card;
