import { toCamelCase } from '@/modules/TCIUtils';

type Flags = {
  assignSlideshowVideoActivity?: boolean;
  autoplayAudio?: boolean;
  canUseUpdatedSpeechstream?: boolean;
  canvasLti13?: boolean;
  gradeByAssignment?: boolean;
  separateAssignNotebookReading?: boolean,
  showAdvancedStandards?: boolean;
  showRandomizeAnswerChoices?: boolean;
  showSpeechstreamToolbar?: boolean;
  updatedGradesTable?: boolean;
  displaySlideGroupHeaders?: boolean;
  newLessonOverviewPage?: boolean;
};

const useFlags = () => {
  const flags = $('#slug-attributes').data('flags') as Flags;

  if (!flags) return {} as Flags;

  return toCamelCase(flags) as Flags;
};

export default useFlags;
