import React from 'react';
import { getNotebookPath } from '@/components/shared/LessonOverview/Cards/NotebookCards';
import { Lesson, Program, SectionType } from '@/types/api/LessonOverview';
import NotebookCard from './NotebookCard';

interface NotebookCardsProps {
  lesson: Lesson;
  program: Program;
  sections: SectionType[];
  stafferId?: number;
  unitColor: string;
}

const NotebookCards = ({ lesson, program, sections, stafferId, unitColor }: NotebookCardsProps) => (
  <>
    {sections.map(
      section => section.notebooks.map(notebook => (
        <NotebookCard
          fastTrack={notebook.fastTrack}
          key={notebook.id}
          lessonId={lesson.id}
          notebookName={notebook.title}
          notebookPath={getNotebookPath(section, notebook, lesson, program)}
          program={program}
          section={section}
          stafferId={stafferId}
          type="notebook"
          unitColor={unitColor}
        />
      )),
    )}
  </>
);

export default NotebookCards;
