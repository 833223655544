import React from 'react';
import I18n from 'i18n-js';
import { Lesson, Program, SectionType, StafferSlideShows } from '@/types/api/LessonOverview';
import { ActivityCard, ReadingCard, SectionVideoCard } from '@/components/shared/LessonOverview/Cards';
import FirstTCICard from './FirstTCICard';
import TCISlideShows from './TCISlideShows';
import { readingPath, sectionsWithLessonVideos } from '../utils';

interface FlexibleLessonOptionsProps {
  hideTabs: boolean;
  lesson: Lesson;
  program: Program;
  sections: SectionType[];
  showArchived: boolean;
  slideShows: StafferSlideShows;
  stafferId: number;
}

const FlexibleLessonOptions = ({
  hideTabs,
  lesson,
  program,
  sections,
  showArchived,
  slideShows,
  stafferId,
}: FlexibleLessonOptionsProps) => {
  const shouldRenderSlideShow = slideShow => showArchived || !slideShow?.archived;

  return (
    <>
      <ReadingCard
        bannerBorderColor="green"
        bannerLabel="reading"
        lessonId={lesson.id}
        linkLabel={program.referenceTextLabel}
        readingPath={
          readingPath(
            sections.filter(s => s.kind === 'default' && s.title.includes('Introduction'))[0],
            program,
            sections,
          )
        }
        program={program}
        sections={sections.filter(s => s.kind === 'default' && s.investigation === false)}
        stafferId={stafferId}
        type="reading"
      >
        {I18n.t('lesson_overview_reading_description')}
      </ReadingCard>

      <FirstTCICard
        hideTabs={hideTabs}
        lessonId={lesson.id}
        programId={program.id}
        shouldRenderSlideShow={shouldRenderSlideShow(slideShows?.tci?.first)}
        slideShow={slideShows?.tci?.first}
        stafferId={stafferId}
      />

      <TCISlideShows
        hideTabs={hideTabs}
        lesson={lesson}
        program={program}
        showArchived={showArchived}
        slideShows={slideShows.tci.newSlideShows}
        stafferId={stafferId}
      />

      {/* Sections with videos associated e.g. Super Simple Science Cards */}
      {Object.values(sectionsWithLessonVideos(sections)).map(section => (
        <SectionVideoCard
          key={section.id}
          lessonId={lesson.id}
          program={program}
          readingPath={readingPath(section, program, sections)}
          section={section}
          stafferId={stafferId}
        />
      ))}

      <TCISlideShows
        hideTabs={hideTabs}
        lesson={lesson}
        program={program}
        showArchived={showArchived}
        slideShows={slideShows.tci.oldSlideShows}
        stafferId={stafferId}
      />

      {slideShows.modules.filter(module => module.showOnLessonOverview && module.activityType).map(module => (
        <ActivityCard
          key={module.id}
          lessonId={lesson.id}
          programId={program.id}
          slideShow={module}
          stafferId={stafferId}
          hideTabs={hideTabs}
        />
      ))}
    </>
  );
};

export default FlexibleLessonOptions;
