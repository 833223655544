import React from 'react';
import I18n from '@/modules/i18n';
import { CardType } from '@/types/api/LessonOverview';
import ActionBar from '@/components/shared/LessonOverview/Cards/ActionBar';
import Card, { CardBody } from './Card';
import { icons, lessonModuleIcons } from './icons';
import SlideConditionsIcon from '@/components/common/SlideConditionsIcon';

interface ActivityCardProps {
  hideTabs?: boolean;
  lessonId: number;
  programId: number;
  slideShow: {
    activityType: CardType;
    archived?: boolean;
    description: string;
    hasSlideConditions: boolean;
    id: number;
    is_module: boolean;
    links: {
      unfilteredPresentPath?: string;
      presentPath: string;
    };
    truncatedDescription: string;
    truncatedTitle: string;
    useNewBuilder: boolean;
    useForInvestigations: boolean;
    useForUnitProjects: boolean;
  };
  stafferId?: number;
}

const ActivityCard = ({
  hideTabs, lessonId, programId, slideShow, stafferId,
}: ActivityCardProps) => {
  const iconSrc = () => {
    if (slideShow.is_module) return lessonModuleIcons[slideShow.activityType];

    if (slideShow.archived) return icons[`archived_${slideShow.activityType}`];

    return icons[slideShow.activityType];
  };

  const lowerIcon = () => {
    if (!slideShow.hasSlideConditions || stafferId == null) return null;

    return (
      <SlideConditionsIcon
        iconClassName="svg-green"
        tooltipContent={I18n.t('slideshow_slide_conditions')}
      />
    );
  };

  return (
    <Card
      archived={slideShow.archived}
      bannerBorderColor={slideShow.archived ? 'very-dark-grey' : 'green'}
      label={slideShow.activityType}
      type={slideShow.activityType}
    >
      <CardBody
        ariaLabel={slideShow.description}
        iconSrc={iconSrc()}
        linkHref={slideShow?.links?.unfilteredPresentPath}
        linkLabel={slideShow.truncatedTitle}
        lowerIcon={lowerIcon()}
        openInNewWindow
      >
        {slideShow.truncatedDescription}
      </CardBody>
      {stafferId && (
        <ActionBar
          programId={programId}
          slideShow={slideShow}
          lessonId={lessonId}
          stafferId={stafferId}
          teacherCard={false}
          hideTabs={hideTabs}
        />
      )}
    </Card>
  );
};

export default ActivityCard;
