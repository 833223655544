import create from 'zustand';
import cn from '@/utils/cn';
import I18n from '@/modules/i18n';

export const BUTTON_CLASSES = cn(
  'tw-flex tw-items-center tw-justify-between tw-gap-2',
  'text-sideways-lr shadow-soft',
  'tw-fixed tw-top-[200px] tw-right-0',
  'tw-text-white tw-text-lg tw-font-semibold tw-h-max tw-w-[38px] tw-py-4',
  'tw-cursor-pointer tw-z-20 tw-transition-all',
  'tw-border-none tw-rounded-tl-xl tw-rounded-bl-xl',
  'tw-bg-[#F16E00] focus:tw-text-white focus:tw-bg-[#D96300] focus:tw-border-[#F16E00]',
  'hover:tw-text-white hover:tw-bg-[#D96300] hover:tw-border-[#F16E00]',
);

export const HEADER_BUTTON_CLASSES = cn(
  ' tw-w-7 tw-h-7 tw-box-border tw-rounded-full tw-shadow-none tw-outline-none tw-border-none',
  'tw-fill-tci-purple tw-p-2 tw-inline-flex tw-items-center tw-justify-center tw-cursor-pointer',
  'tw-bg-light-grey hover:tw-bg-tci-purple hover:tw-fill-light-grey tw-transition-all',
  'focus:tw-text-light-grey hover:tw-text-light-grey',
  'focus:tw-bg-tci-purple focus:tw-fill-light-grey focus:tw-outline-none',
);

export type PlanningContext = 'lesson' | 'unit';

export type Resource = {
  id: number;
  html: string;
  title: string;
};

/**
 * Dynamic translations that are required in config/locales are formatted as follows
 * dialog.open: open_context_planning_dialog
 * dialog.standard: context_planning_dialog
 * title: context_planning
 *
 */
export const TRANSLATIONS = {
  dialog: {
    close: I18n.t('close_dialog') as string,
    collapse: I18n.t('exit_full_screen') as string,
    expand: I18n.t('enter_full_screen') as string,
    open: (context: PlanningContext) => I18n.t(`open_${context}_planning_dialog`) as string,
    standard: (context: PlanningContext) => I18n.t(`${context}_planning_dialog`) as string,
  },
  title: (context: PlanningContext) => I18n.t(`${context}_planning`) as string,
  printMessage: I18n.t('print_overview') as string,
  visibilityMessage: I18n.t('not_visible_to_students') as string,
} as const;

interface UsePlanningDrawer {
  close: () => void;
  collapse: () => void;
  expand: () => void;
  isExpanded: boolean;
  isOpen: boolean;
  open: () => void;
  toggleExpanded: () => void;
  toggleOpen: () => void;
}

export const usePlanningDrawer = create<UsePlanningDrawer>((set) => ({
  close: () => set({ isOpen: false, isExpanded: false }),
  collapse: () => set({ isExpanded: false }),
  expand: () => set({ isExpanded: true }),
  isExpanded: false,
  isOpen: false,
  open: () => set({ isOpen: true }),
  toggleExpanded: () => set((prev) => ({ ...prev, isExpanded: !prev.isExpanded })),
  toggleOpen: () => set((prev) => {
    const nextIsOpen = !prev.isOpen;
    // If the drawer is open, keep the expanded state. Otherwise, collapse the drawer.
    let nextExpanded = nextIsOpen ? prev.isExpanded : false;

    return { isExpanded: nextExpanded, isOpen: nextIsOpen };
  }),
}));
