import React from 'react';
import I18n from 'i18n-js';
import { Lesson, Program, SectionType } from '@/types/api/LessonOverview';
import LessonSubheader from '@/components/shared/LessonOverview/LessonSubheader';
import { getSectionsByKind } from '@/components/shared/LessonOverview/utils';
import { MoreReadingCards, ReferenceMaterialCard } from './Cards';
import { groupBy } from '@/modules/TCIUtils';

interface DiveDeeperProps {
  lesson: Lesson;
  program: Program
  sections: SectionType[];
  stafferId?: number;
}

const DiveDeeper = ({ lesson, program, sections, stafferId }: DiveDeeperProps) => {
  const sectionsByKind = getSectionsByKind(sections);

  const moreReadingTypes = Object.keys(sectionsByKind).filter(kind => (
    !['default', 'unit projects', 'key science concept'].includes(kind)
  ));
  const { referenceMaterialAssignments } = lesson;
  const groupedAssignments = groupBy(referenceMaterialAssignments, 'type');

  if (moreReadingTypes.length === 0 && referenceMaterialAssignments.length === 0) return null;

  return (
    <LessonSubheader
      title={I18n.t('dive_deeper')}
      description={I18n.t('lesson_subheader_dive_deeper')}
      isStaffer={!!stafferId}
    >
      <MoreReadingCards
        lesson={lesson}
        program={program}
        sectionsByKind={sectionsByKind}
        stafferId={stafferId}
        types={moreReadingTypes}
      />
      {Object.keys(groupedAssignments).map(assignmentType => (
        <ReferenceMaterialCard
          key={`reference-material-card-${assignmentType}`}
          assignments={groupedAssignments[assignmentType]}
          programId={program.id}
          type={assignmentType}
        />
      ))}
    </LessonSubheader>
  );
};

export default DiveDeeper;
