import video_activity from '@/../images/icons/video-activity.svg';
import classroom_activity from '@/../images/icons/hands-on.svg';
import lesson_outline from '@/../images/icons/lesson-outline.svg';
import reading from '@/../images/icons/reading.svg';

export const ICON_MAP = {
  video_activity,
  classroom_activity,
  investigation: classroom_activity,
  professional_development: classroom_activity,
  lesson_outline,
  reading,
};

export const shouldRenderSlideShow = (slideShow) => {
  if (slideShow.useForInvestigations) return false;
  if (slideShow.useForUnitProjects) return false;
  if (!slideShow.activityType) return false;
  if (slideShow.activityType === 'marketing') return false;

  return true;
};
