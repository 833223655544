import React, { useMemo } from 'react';
import useFlags from '@/hooks/useFlags';
import { grade_by_student_shared_question_answers_path } from '@/modules/routes';
import Popover from '@/components/ui/Popover';
import { IStudentRow } from '../../types';
import { columnHelper } from '../../variables';
import useGradesTableStore from '../../store/useGradesTableStore';
import { HeaderCell } from '../Cells';
import useSectionAssignments from '../../hooks/queries/useSectionAssignments';
import useClassroomLesson from '../../hooks/queries/useClassroomLesson';
import GradeCell from '../Cells/GradeCells';
import useSectionAssignmentExerciseResults from '../../hooks/queries/exerciseResults/useSectionAssignmentExerciseResults';
import NotebookGradeByMenu from '@/components/staffer/NotebookGradeByMenu';
import { gradingByAssignment } from '../../Utils';

const useSectionAssignmentColumns = () => {
  const programId = useGradesTableStore(state => state.programId);
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);

  const { updatedGradesTable } = useFlags();

  const { data } = useSectionAssignments();
  const { data: exerciseResults } = useSectionAssignmentExerciseResults();
  const { data: classroomLesson } = useClassroomLesson(classroom.id, lesson?.id);

  return useMemo(() => {
    if (!updatedGradesTable) return [];
    if (!gradingByAssignment(classroomLesson)) return [];

    const accessor = (row: IStudentRow) => row.student.id;

    return data.map(assignment => columnHelper.accessor(accessor, {
      header: () => {
        const assignmentModels = Object.values(assignment.assignment_models_hash).flat();
        const fullTitles = [
          ...new Set(assignmentModels.map(am => am.parent_section ? am.section_full_title : am.label))
        ].sort().join(', ');

        const subtitle = assignment.name || fullTitles;
        const label = `${assignment.reading_assignment_label}: ${subtitle}`;

        return (
          <HeaderCell title={label}>
            <div className="tw-font-light">
              <Popover>
                <Popover.Trigger
                  className={`tw-cursor-pointer tw-bg-none tw-outline-none tw-border-none tw-bg-inherit tw-absolute
                                tw-right-1 tw-top-1 tw-text-lg hover:tw-bg-opacity-10 hover:tw-bg-tci-purple`}
                >
                  <i className="fa fa-ellipsis-v" />
                </Popover.Trigger>
                <Popover.Content
                  className="tw-w-auto tw-z-[1040] tw-flex tw-flex-col"
                  onOpenAutoFocus={(e) => {
                    e.preventDefault();
                    // The default focusable element is the bottom element which can be disabled
                    // and opens its tooltip which is kind of annoying behavior. Focus the always enabled el instead
                    const div = e.target as HTMLDivElement;
                    (div.children[0] as HTMLAnchorElement).focus();
                  }}
                  side="top"
                >
                  <NotebookGradeByMenu
                    classroom={classroom}
                    classroomLesson={classroomLesson}
                    lesson={lesson}
                  />
                </Popover.Content>
              </Popover>
            </div>
          </HeaderCell>
        );
      },
      id: `notebook-${assignment.id}`,
      cell: info => {
        const url = grade_by_student_shared_question_answers_path(
          info.getValue(),
          {
            assignment_id: assignment.id,
            classroom_id: classroom.id,
            lesson_id: lesson.id,
            program_id: programId,
          });

        return (
          <GradeCell
            id={`assignment-${assignment.id}-student-score`}
            status={{ color: '', text: '-' }}
            url={url}
          />
        );
      },
    }));
  }, [programId, classroom?.id, lesson?.id, data, exerciseResults, updatedGradesTable]);
};

export default useSectionAssignmentColumns;
