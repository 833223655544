import React, { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import EditScore from '@/components/staffer/Scores/EditScore';
import useFlags from '@/hooks/useFlags';
import Popover from '@/components/ui/Popover';
import { IStudentRow } from '../../types';
import { columnHelper, SECTION_COLLAPSE_ID } from '../../variables';
import useGradesTableStore from '../../store/useGradesTableStore';
import useScorableNotebooks from '../../hooks/queries/useScorableNotebooks';
import useSectionNotebookExerciseResults from '../../hooks/queries/exerciseResults/useSectionNotebookExerciseResults';
import { QueryKeys } from '../../GradesTableQueryClientProvider';
import useClassroomLesson from '../../hooks/queries/useClassroomLesson';
import SectionNotebookGradeCell
  from '../Cells/GradeCells/SectionNotebookGradeCell';
import { HeaderCell } from '../Cells';
import { gradingByAssignment, gradingBySection } from '../../Utils';

const useSectionNotebooksColumns = () => {
  const programId = useGradesTableStore(state => state.programId);
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);
  const isSectionNotebooksExpanded = useGradesTableStore(state => state.isSectionNotebooksExpanded);

  const { updatedGradesTable } = useFlags();

  const { data: scorableNotebooks } = useScorableNotebooks();
  const { data: exerciseResults } = useSectionNotebookExerciseResults();
  const { data: classroomLesson } = useClassroomLesson(classroom.id, lesson?.id);

  const queryClient = useQueryClient();

  return useMemo(() => {
    const accessor = (row: IStudentRow) => row.student.id;

    if (gradingByAssignment(classroomLesson)) return [];

    const columns = scorableNotebooks.map(notebook => columnHelper.accessor(accessor, {
      header: () => (
        <HeaderCell
          title={notebook.section_header}
          maxScore={(updatedGradesTable && gradingBySection(classroomLesson)) ? classroomLesson.section_max_scores[notebook.section_id] : null}
        >
          {updatedGradesTable ? (
            <Popover>
              <Popover.Trigger
                className={`tw-cursor-pointer tw-bg-none tw-outline-none tw-border-none tw-bg-inherit tw-absolute
                                tw-right-1 tw-top-1 tw-text-lg`}
              >
                <i className="fa fa-ellipsis-v" />
              </Popover.Trigger>
              <Popover.Content
                className="tw-w-auto tw-z-[1040] tw-flex tw-flex-col"
                onOpenAutoFocus={(e) => {
                  e.preventDefault();
                  // The default focusable element is the bottom element which can be disabled
                  // and opens its tooltip which is kind of annoying behavior. Focus the always enabled el instead
                  const div = e.target as HTMLDivElement;
                  div.getElementsByTagName('button')[0].focus();
                }}
                side="top"
              >
                <EditScore
                  initialMaxScore={classroomLesson.section_max_scores[notebook.section_id]}
                  classroomId={classroom.id}
                  sectionId={notebook.section_id}
                  programId={programId}
                  callback={(newScore) => {
                    const scoresHash = { ...classroomLesson.section_max_scores, [notebook.section_id]: newScore };
                    const newMaxScore = Object.values(scoresHash).reduce((acc: number, score: number) => acc + score, 0);
                    queryClient.setQueryData([QueryKeys.ClassroomLesson, classroom.id, lesson.id], {
                      ...classroomLesson,
                      section_max_scores: scoresHash,
                      max_score: newMaxScore,
                    });
                  }}
                />
              </Popover.Content>
            </Popover>
          ) : (
            <>
              {gradingBySection(classroomLesson) && (
                <div className="tw-text-[14px] tw-font-light">
                  <EditScore
                    initialMaxScore={classroomLesson.section_max_scores[notebook.section_id]}
                    classroomId={classroom.id}
                    sectionId={notebook.section_id}
                    programId={programId}
                    callback={(newScore) => {
                      const scoresHash = { ...classroomLesson.section_max_scores, [notebook.section_id]: newScore };
                      const newMaxScore = Object.values(scoresHash).reduce((acc: number, score: number) => acc + score, 0);

                      queryClient.setQueryData([QueryKeys.ClassroomLesson, classroom.id, lesson.id], {
                        ...classroomLesson,
                        section_max_scores: scoresHash,
                        max_score: newMaxScore,
                      });
                    }}
                  />
                </div>
              )}
            </>
          )}
        </HeaderCell>
      ),
      id: `notebook-${notebook.id}`,
      cell: info => (
        <SectionNotebookGradeCell
          notebook={notebook}
          studentId={info.getValue()}
          sectionNotebookMaxScore={classroomLesson.section_max_scores[notebook.section_id]}
        />
      ),
    }));

    return [
      ...(isSectionNotebooksExpanded ? columns : []),
      columnHelper.accessor(accessor, { id: SECTION_COLLAPSE_ID }),
    ];
  }, [programId, classroom?.id, lesson?.id, scorableNotebooks, exerciseResults, isSectionNotebooksExpanded, classroomLesson]);
};

export default useSectionNotebooksColumns;
