import React from 'react';
import I18n from 'i18n-js';
import Tooltip from '@/components/common/Tooltip';
import { CIRCLE_ICON_CLASS } from '../../GoToButton';

type ActionBarButtonType = {
  tag: string
  icon: string;
  onClick?: () => void;
};

export const ACTION_BAR_BTN_BG = 'rgba(52, 2, 91, 0.05)';

const ActionBarButton = ({ icon, tag, onClick } : ActionBarButtonType) => (
  <Tooltip
    content={I18n.t(tag)}
    size="medium"
    distance="15"
    hideOnClick
  >
    <button
      className={CIRCLE_ICON_CLASS}
      style={{ backgroundColor: ACTION_BAR_BTN_BG }}
      onClick={onClick}
      type="button"
      aria-label={I18n.t(tag)}
    >
      <img src={icon} alt="" aria-hidden />
    </button>
  </Tooltip>
);
export default ActionBarButton;
