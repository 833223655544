import React, { useMemo } from 'react';
import { t } from 'i18n-js';
import { columnHelper } from '@/components/staffer/Scores/GradesTable/variables';
import { IStudentRow } from '@/components/staffer/Scores/GradesTable/types';
import { HeaderCell } from '@/components/staffer/Scores/GradesTable/Table/Cells';
import useReadingGames from '@/components/staffer/Scores/GradesTable/hooks/queries/useReadingGames';
import useGradesTableStore from '@/components/staffer/Scores/GradesTable/store/useGradesTableStore';
import ReadingCheckGradeCell
  from '@/components/staffer/Scores/GradesTable/Table/Cells/GradeCells/ReadingCheckGradeCell';
import useClassroomLesson from '../../hooks/queries/useClassroomLesson';
import { gradingByAssignment } from '../../Utils';

const useReadingChecksColumn = () => {
  const lesson = useGradesTableStore(state => state.lesson);
  const classroom = useGradesTableStore(state => state.classroom);

  const { data: classroomLesson } = useClassroomLesson(classroom.id, lesson?.id);
  const { data: readingGames } = useReadingGames(lesson?.id);

  const maxScore = readingGames.reduce((acc, cur) => acc + (cur.max_points || 0), 0);

  return useMemo(() => {
    if (gradingByAssignment(classroomLesson)) return [];
    if (readingGames.length === 0 || maxScore === 0) return [];

    const accessor = (row: IStudentRow) => row.student.id;

    return ([
      columnHelper.accessor(accessor, {
        cell: info => <ReadingCheckGradeCell studentId={info.getValue()} />,
        header: () => (
          <HeaderCell title={t('reading_checks')} maxScore={maxScore} />
        ),
        id: 'reading-games',
      }),
    ]);
  }, [maxScore]);
};

export default useReadingChecksColumn;
