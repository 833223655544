import React from 'react';
import I18n from 'i18n-js';
import Modal, { useModalManager } from '@/components/common/Modal';
import Form from '@/components/shared/LessonOverview/Cards/ActionBar/Form';
import ISlideShow from '@/types/api/SlideShow';
import ActionBarLink from '@/components/shared/NewLessonOverview/Cards/ActionBar/ActionBarButtons/ActionBarLink';
import editIcon from 'images/icons/editSlide.svg';
import ActionBarButton from '../ActionBarButton/ActionBarButton';

type EditButtonProps = {
  programId: number;
  lessonId: number;
  slideShow: ISlideShow;
  fromSlideShow?: boolean;
  editExistingSlideShow?: boolean;
};

const Edit = ({ programId, lessonId, slideShow, fromSlideShow, editExistingSlideShow }: EditButtonProps) => {
  const modalManager = useModalManager();

  if (editExistingSlideShow) {
    return (
      <ActionBarLink
        href={`${slideShow.links.editPath}${slideShow.useNewBuilder ? '?new_builder=true' : ''}`}
        tag="edit"
        icon={editIcon}
        useNewTab={false}
      />
    );
  }

  return (
    <>
      <ActionBarButton
        tag="edit"
        icon={editIcon}
        onClick={modalManager.open}
      />
      <Modal
        closeModal={modalManager.close}
        isOpen={modalManager.isOpen}
        headerText={I18n.t('save_new_presentation')}
      >
        <Form
          button="edit"
          id={slideShow.id}
          slideShowId={slideShow.slideShowId}
          title={slideShow.title}
          type={slideShow.className}
          useNewBuilder={slideShow.useNewBuilder}
          programId={programId}
          lessonId={lessonId}
          fromSlideShow={fromSlideShow}
        />
      </Modal>
    </>
  );
};

export default Edit;
