import React, { useState } from 'react';
import I18n from 'i18n-js';
import { Program } from '@/types/api/LessonOverview';
import * as Routes from '@/modules/routes';
import printIcon from 'images/icons/printK5.svg';
import deleteIcon from 'images/icons/delete-lined.svg';
import { dateTimeToUserTimeZone } from '@/utils/functions';
import ActionBarLink from '../Cards/ActionBar/ActionBarButtons/ActionBarLink';
import Copy from '../Cards/ActionBar/ActionBarButtons/SlideShowButtons/Copy';
import Edit from '../Cards/ActionBar/ActionBarButtons/SlideShowButtons/Edit';
import LessonSubheader from '../LessonSubheader';
import Card, { CardBody } from '../Cards/Card/Card';
import ActionBar from '../Cards/ActionBar';
import ShowSlideshowCheckbox from '../ShowSlideshowCheckbox';
import { ICON_MAP, shouldRenderSlideShow } from './utils';
import useLessonOverviewContext from '../useLessonOverviewContext';

type LessonOptionsProps = {
  isStaffer: boolean;
  program: Program;
  slideShows: any;
  unitColor: string;
};

const LessonOptions = ({ isStaffer, program, slideShows, unitColor }: LessonOptionsProps) => {
  if (!slideShows) return null;

  const stafferId = useLessonOverviewContext(state => state.stafferId);
  const lessonId = useLessonOverviewContext(state => state.lessonId);
  const label = slideShow => `${I18n.t('print')} ${slideShow?.title}`;

  const firstTCISlideShow = slideShows.tci?.first;
  const newTCISlideShows = slideShows.tci?.newSlideShows ?? [];
  const oldTCISlideShows = slideShows.tci?.oldSlideShows ?? [];
  const teacherSlideShows = slideShows.staffer ?? [];
  const slideShowsToRender = [...newTCISlideShows, ...oldTCISlideShows];

  const [showTeacherSlideShows, setShowTeacherSlideShows] = useState(false);

  return (
    <LessonSubheader title={I18n.t('choose_a_lesson')}>
      {isStaffer && (
        <ShowSlideshowCheckbox isChecked={showTeacherSlideShows} setIsChecked={setShowTeacherSlideShows} />
      )}
      <div className="tw-h-full tw-w-full tw-flex tw-flex-row tw-flex-wrap">
        <Card
          label={firstTCISlideShow.activityType}
          type={firstTCISlideShow.activityType}
          unitColor={unitColor}
        >
          <CardBody
            ariaLabel={firstTCISlideShow.title}
            iconSrc={ICON_MAP[firstTCISlideShow.activityType]}
            linkLabel={firstTCISlideShow.title}
            linkHref={firstTCISlideShow.links?.unfilteredPresentPath}
            showThumbnail
            type={firstTCISlideShow.activityType}
            unitColor={unitColor}
            time={firstTCISlideShow.lesson.class_time}
            page="-"
          />
          {isStaffer && (
            <ActionBar
              iconType="play"
              label={firstTCISlideShow.title}
              href={firstTCISlideShow.links?.unfilteredPresentPath}
              lessonId={lessonId}
              program={program}
              stafferId={stafferId}
            >
              <Copy programId={program.id} lessonId={lessonId} slideShow={firstTCISlideShow} />
              <Edit programId={program.id} lessonId={lessonId} slideShow={firstTCISlideShow} />
              <ActionBarLink
                href={Routes.pdf_shared_program_lesson_path(program.id, lessonId, {
                  locale: I18n.locale,
                })}
                tag="print"
                icon={printIcon}
                label={label(firstTCISlideShow)}
              />
            </ActionBar>
          )}
        </Card>

        {slideShowsToRender?.map((slideShow) => {
          if (!shouldRenderSlideShow(slideShow)) return (<></>);

          return (
            <Card
              label={slideShow.activityType}
              type={slideShow.activityType}
              unitColor={unitColor}
              key={slideShow.id}
            >
              <CardBody
                ariaLabel={slideShow.title}
                iconSrc={ICON_MAP[slideShow.activityType]}
                linkLabel={slideShow.title}
                linkHref={slideShow.links?.unfilteredPresentPath}
                showThumbnail
                type={slideShow.activityType}
                unitColor={unitColor}
                time="-"
                page="-"
              />
              {isStaffer && (
                <ActionBar
                  iconType="play"
                  href={slideShow.links?.unfilteredPresentPath}
                  lessonId={lessonId}
                  program={program}
                  stafferId={stafferId}
                >
                  <Copy programId={program.id} lessonId={lessonId} slideShow={slideShow} />
                  <Edit programId={program.id} lessonId={lessonId} slideShow={slideShow} />
                  <ActionBarLink
                    href={slideShow.links.printUrl}
                    tag="print"
                    icon={printIcon}
                    label={label(slideShow)}
                  />
                </ActionBar>
              )}
            </Card>
          );
        })}
      </div>

      <div className="tw-h-full tw-w-full tw-flex tw-flex-row tw-flex-wrap">
        {isStaffer && showTeacherSlideShows && teacherSlideShows?.map((slideShow) => {
          if (!shouldRenderSlideShow(slideShow)) return (null);

          const slideShowUpdateTime = dateTimeToUserTimeZone(slideShow.updatedAt, 'l');
          const slideShowUpdateLabel = I18n.t('last_updated_on', { time: slideShowUpdateTime });
          const indicatorMessage = I18n.t('teacher_slideshow_tooltip');

          return (
            <Card
              label={slideShow.activityType}
              type={slideShow.activityType}
              unitColor={unitColor}
            >
              <CardBody
                ariaLabel={slideShow.title}
                iconSrc={ICON_MAP[slideShow.activityType]}
                linkLabel={slideShow.title}
                linkHref={slideShow.links?.unfilteredPresentPath}
                type={slideShow.activityType}
                unitColor={unitColor}
                showThumbnail={false}
                tooltipIndicator={indicatorMessage}
                topLabel={slideShowUpdateLabel}
              />
              <ActionBar
                iconType="play"
                href={slideShow.links?.unfilteredPresentPath}
                lessonId={lessonId}
                program={program}
                stafferId={stafferId}
              >
                <Copy programId={program.id} lessonId={lessonId} slideShow={slideShow} />
                {!!slideShow.links?.editPath && (
                  <Edit programId={program.id} lessonId={lessonId} slideShow={slideShow} editExistingSlideShow />
                )}
                {!!slideShow.links?.printUrl && (
                  <ActionBarLink
                    href={slideShow.links.printUrl}
                    tag="print"
                    icon={printIcon}
                  />
                )}
                {!!slideShow.links?.deletePath && (
                  <ActionBarLink
                    href={slideShow.links.deletePath}
                    tag="delete"
                    icon={deleteIcon}
                    dataMethod="delete"
                    dataConfirm="Are you sure you want to delete this lesson guide?"
                    useNewTab={false}
                  />
                )}
              </ActionBar>
            </Card>
          );
        })}
      </div>
    </LessonSubheader>
  );
};

export default LessonOptions;
