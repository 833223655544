import React from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { plato_api_image_model_path } from '@/modules/routes';
import { QueryKeys } from '@/components/admin/QuestionImage/utils';
import IImageModel from '@/components/interfaces/ImageModel';
import { useModalManager } from '@/components/common/Modal';
import ConfirmDeleteModal from '@/components/common/Modal/ConfirmDeleteModal';
import I18n from 'i18n-js';

interface DeleteProps {
  afterDelete: (id?: number) => any;
  imageModel: IImageModel;
}

const Delete = ({ afterDelete, imageModel }: DeleteProps) => {
  const deleteModalManager = useModalManager();
  const { mutate, isLoading } = useMutation({
    mutationKey: [QueryKeys.ImageModels, QueryKeys.Delete, imageModel.id],
    mutationFn: () => (
      axios
        .delete(plato_api_image_model_path(imageModel.id))
        .then(() => afterDelete(imageModel.id))
    ),
  });

  return (
    <>
      {deleteModalManager.isOpen && (
        <ConfirmDeleteModal
          headerText={I18n.t('delete_image')}
          confirmationText={I18n.t('delete_image_message')}
          confirmButtonText={I18n.t('delete')}
          isLoading={isLoading}
          isOpen={deleteModalManager.isOpen}
          onCancel={deleteModalManager.close}
          onConfirm={mutate}
        />
      )}
      <button
        className="btn btn--red btn--sm"
        disabled={isLoading}
        onClick={deleteModalManager.open}
        type="button"
      >
        Delete
      </button>
    </>
  );
};

export default Delete;
