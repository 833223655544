import React from 'react';
import I18n from 'i18n-js';
import Tooltip from '@/components/common/Tooltip';
import { ACTION_BAR_BTN_BG } from '../ActionBarButton/ActionBarButton';
import { CIRCLE_ICON_CLASS } from '../../GoToButton';

type ActionBarLinkType = {
  href: string;
  tag: string
  icon: string;
  label?: string;
  dataMethod?: string;
  dataConfirm?: string;
  useNewTab?: boolean;
};

const ActionBarLink = ({ href, icon, label, tag, useNewTab = true, dataMethod, dataConfirm }: ActionBarLinkType) => (
  <Tooltip
    content={I18n.t(tag)}
    size="medium"
    distance="15"
    hideOnClick
  >
    <a
      className={CIRCLE_ICON_CLASS}
      style={{ backgroundColor: ACTION_BAR_BTN_BG }}
      href={href}
      target={useNewTab ? '_blank' : null}
      aria-label={label}
      data-method={dataMethod}
      data-confirm={dataConfirm}
    >
      <img
        aria-hidden
        className="tw-w-[1.5rem]"
        src={icon}
        alt=""
      />
    </a>
  </Tooltip>
);
export default ActionBarLink;
