import { GradeByType } from '@/components/staffer/NotebookGradeByMenu/types';
import { IClassroomLesson } from './types';

export const gradingByAssignment = (classroomLesson: IClassroomLesson) => {
  return classroomLesson?.grading_notebook_by === GradeByType.Assignment;
};

export const gradingByLesson = (classroomLesson: IClassroomLesson) => {
  return classroomLesson?.grading_notebook_by === GradeByType.Lesson;
};

export const gradingBySection = (classroomLesson: IClassroomLesson) => {
  return classroomLesson?.grading_notebook_by === GradeByType.Section;
};
