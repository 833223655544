import moment from 'moment';

function hexToBaseSixteen(hex: string): string {
  return parseInt(hex, 16).toString();
}

export function hexToRGB(hexCode: string): string {
  const hex = hexCode.replace('#', '');

  const r = hexToBaseSixteen(hex.substring(0, 2));
  const g = hexToBaseSixteen(hex.substring(2, 2));
  const b = hexToBaseSixteen(hex.substring(4, 2));

  return `rgba(${r}, ${g}, ${b})`;
}

export function dateTimeToUserTimeZone(date: string, format: string) {
  const datetime = moment.utc(date);
  const userTimezone = moment.tz.guess(true);
  const datetimeLocal = moment.tz(datetime, userTimezone);

  return datetimeLocal.format(format);
}
