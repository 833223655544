import React, { useState } from 'react';
import LegacyAssignButton, { AssignButtonProps } from '@/components/shared/LessonOverview/Cards/AssignButton';
import calendarPlusIcon from 'images/icons/calendar-plus.svg';
import assignedIcon from 'images/icons/assigned.svg';

const AssignButton = ({
  circle = false,
  initialValues,
  isAssigned: initialIsAssigned,
  lessonId,
  program,
  stafferId,
}: AssignButtonProps) => {
  const [isAssigned, setIsAssigned] = useState(initialIsAssigned);

  return (
    <LegacyAssignButton
      circle={circle}
      handlePostSave={() => setIsAssigned(true)}
      initialValues={initialValues}
      isAssigned={isAssigned}
      lessonId={lessonId}
      program={program}
      stafferId={stafferId}
    >
      <img
        aria-hidden
        alt=""
        className="tw-w-[26px] tw-h-[26px]"
        src={isAssigned ? assignedIcon : calendarPlusIcon}
      />
    </LegacyAssignButton>
  );
};

export default AssignButton;
