import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import * as Routes from '@/modules/routes';
import { IApiResponse } from '../../../types';
import useGradesTableStore from '../../../store/useGradesTableStore';
import useClassroomLesson from '../useClassroomLesson';
import { gradingByAssignment } from '../../../Utils';

const useSectionAssignmentExerciseResults = () => {
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);
  const { data: classroomLesson } = useClassroomLesson(classroom.id, lesson?.id);
  const isEnabled = !!(classroom?.id && lesson?.id && gradingByAssignment(classroomLesson));

  return useQuery(['ExerciseResults', 'useSectionAssignmentExerciseResults', classroom?.id, lesson?.id], () => (
    axios.get<IApiResponse>(Routes.plato_api_section_assignment_results_exercise_results_path({
      only: [
        'id', 'student_id', 'exercise_type', 'exercise_id', 'score', 'completed_at', 'requires_clever_passback',
        'lms_settings', 'updated_since_graded',
      ],
      classroom_id: classroom.id,
      lesson_id: lesson.id,
    })).then(res => res.data.data)
  ), { enabled: isEnabled, placeholderData: [] });
};

export default useSectionAssignmentExerciseResults;
