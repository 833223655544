import { useContext } from 'react';
import { useStore } from 'zustand';
import { LessonOverviewContext } from '@/components/shared/NewLessonOverview/NewLessonOverviewStoreProvider';
import { CreateLessonOverviewStoreVariables } from '@/components/shared/NewLessonOverview/types';

const useLessonOverviewContext = <T>(
  selector: (state: CreateLessonOverviewStoreVariables) => T,
  equalityFn?: (left: T, right: T) => boolean,
) => {
  const store = useContext(LessonOverviewContext);

  return useStore(store, selector, equalityFn);
};

export default useLessonOverviewContext;
