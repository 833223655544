import React, { Component, Fragment, createRef } from 'react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { ReferenceMaterialTypes } from '@/components/interfaces/ReferenceMaterial';
import I18n from 'modules/i18n';
import * as Routes from 'routes';
import Tooltip from 'common/Tooltip';
import { capitalizeWords } from 'TCIUtils';
import Modal, { CancelButton, SubmitButton } from 'common/Modal';
import SaveStatus from './SaveStatus';

const REFERENCE_MATERIAL_TRANSLATION_KEYS_CONFIG = {
  [ReferenceMaterialTypes.BIOGRAPHIES_TYPE]: 'assignment_biographies',
  [ReferenceMaterialTypes.CAREER_PROFILES_TYPE]: 'assignment_career_profiles',
  [ReferenceMaterialTypes.CIVICS_TYPE]: 'assignment_civics',
  [ReferenceMaterialTypes.MAPS_TYPE]: 'assignment_maps',
  [ReferenceMaterialTypes.PRIMARY_SOURCES_TYPE]: 'assignment_primary_sources',
  [ReferenceMaterialTypes.SCIENCE_AND_ENGINEERING_TYPE]: 'assignment_science_and_engineering',
  [ReferenceMaterialTypes.SUPREME_COURT_CASES_TYPE]: 'assignment_supreme_court_cases',
};

export default class SubmitNotebookButton extends Component {
  static propTypes = {
    classroomId: PropTypes.number.isRequired,
    exerciseName: PropTypes.string.isRequired,
    forUnitNotebooks: PropTypes.bool,
    initiallyDirty: PropTypes.bool,
    lastUpdated: PropTypes.string,
    notebookId: PropTypes.number,
    notebookTextOnly: PropTypes.bool,
    pastDueDate: PropTypes.bool,
    programId: PropTypes.number.isRequired,
    referenceMaterialType: PropTypes.oneOf([
      ReferenceMaterialTypes.BIOGRAPHIES_TYPE,
      ReferenceMaterialTypes.CAREER_PROFILES_TYPE,
      ReferenceMaterialTypes.CIVICS_TYPE,
      ReferenceMaterialTypes.MAPS_TYPE,
      ReferenceMaterialTypes.PRIMARY_SOURCES_TYPE,
      ReferenceMaterialTypes.SCIENCE_AND_ENGINEERING_TYPE,
      ReferenceMaterialTypes.SUPREME_COURT_CASES_TYPE,
    ]),
    reloadAnchor: PropTypes.string,
    sectionId: PropTypes.number,
    sectionTextOnly: PropTypes.bool,
    singleTurnIn: PropTypes.bool,
    turnedIn: PropTypes.bool,
    unitId: PropTypes.number,
  };

  static defaultProps = {
    forUnitNotebooks: false,
    initiallyDirty: false,
    lastUpdated: null,
    notebookId: null,
    pastDueDate: false,
    referenceMaterialType: null,
    reloadAnchor: null,
    sectionId: null,
    sectionTextOnly: false,
    notebookTextOnly: false,
    singleTurnIn: false,
    turnedIn: false,
    unitId: null,
  };

  static ANSWERED_SELECTOR = '[data-answered=true]:not(.reading-game-question)';

  static UNANSWERED_SELECTOR = '[data-answered=false]:not(.reading-game-question)';

  static QUESTIONS_SELECTOR = '[data-dirty]:not(.reading-game-question)';

  static DIRTY_SELECTOR = '[data-dirty=true]:not(.reading-game-question)';

  static noQuestionsAnswered() {
    return !document.querySelector(SubmitNotebookButton.ANSWERED_SELECTOR);
  }

  static hasUnansweredQuestions() {
    return !!document.querySelector(SubmitNotebookButton.UNANSWERED_SELECTOR);
  }

  constructor(props) {
    super(props);
    this.buttonRef = createRef();

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        this.onMutate();
      });
    });

    this.state = {
      dirty: this.props.initiallyDirty,
      modalOpen: false,
      mutationObserver: mutationObserver,
      noneAnswered: false,
      saveError: false,
      savedTime: null,
      saving: false,
      turnedIn: this.props.turnedIn
    };

    this.submit = this.submit.bind(this);
    this.onMutate = this.onMutate.bind(this);
    this.addMutationListener = this.addMutationListener.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleAutosave = this.handleAutosave.bind(this);
  }

  componentDidMount() {
    this.addQuestionAnswerListeners();

    this.setState({ noneAnswered: SubmitNotebookButton.noQuestionsAnswered() });
  }

  componentDidUpdate() {
    this.addQuestionAnswerListeners();
  }

  componentWillUnmount() {
    this.state.mutationObserver.disconnect();
    window.removeEventListener('autosave', this.handleAutosave);
  }

  addQuestionAnswerListeners() {
    const $questions = $(SubmitNotebookButton.QUESTIONS_SELECTOR);

    $questions.each((_, question) => {
      this.addMutationListener(question);
    });

    window.addEventListener('autosave', this.handleAutosave);
  }

  addMutationListener(element) {
    this.state.mutationObserver.observe(element, { attributeFilter: ['data-dirty'], attributes: true });
  }

  onMutate() {
    if (document.querySelector(SubmitNotebookButton.DIRTY_SELECTOR)) {
      this.setState({ dirty: true, noneAnswered: false });
    }
  }

  sectionTextOnly() {
    return !this.props.notebookId || this.props.sectionTextOnly;
  }

  getSubmitPath() {
    const params = new URLSearchParams(window.location.search);
    const assignmentId = params.get('assignment_id');

    if (this.sectionTextOnly() && !this.props.referenceMaterialType) {
      return Routes.plato_api_turn_in_section_path(
        this.props.sectionId, { assignment_id: assignmentId, classroom_id: this.props.classroomId }
      );
    }

    if (this.props.forUnitNotebooks) {
      return Routes.plato_api_turn_in_unit_notebook_notebook_path(
        this.props.notebookId,
        { program_id: this.props.programId, unit_id: this.props.unitId, assignment_id: assignmentId }
      );
    }

    if (this.props.referenceMaterialType) {
      return Routes.plato_api_exercise_results_reference_material_path(
        this.props.notebookId,
        { program_id: this.props.programId }
      );
    }

    return Routes.plato_api_turn_in_notebook_path(this.props.notebookId,
      { classroom_id: this.props.classroomId, program_id: this.props.programId, assignment_id: assignmentId });
  }

  async submit() {
    this.setState({ saveError: false, saving: true });

    return Axios
      .post(this.getSubmitPath())
      .then(() => {
        // Reload page if can only turn in once:
        if (this.props.singleTurnIn) {
          if (this.props.reloadAnchor) {
            window.location.hash = `#${this.props.reloadAnchor}`;
          }

          window.location.reload();
        }
        else {
          this.setState({
            dirty: false,
            modalOpen: false,
            saveError: false,
            savedTime: new Date(),
            saving: false,
            turnedIn: true
          });
        }
        this.triggerTurnInEvent();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ saveError: true, saving: false });
      });
  }

  referenceMaterialTranslationKey() {
    if (!this.props.referenceMaterialType) return;

    return REFERENCE_MATERIAL_TRANSLATION_KEYS_CONFIG[this.props.referenceMaterialType];
  }

  questionIds() {
    const selector = this.props.forUnitNotebooks ? '.section-content' : '#content_container';

    const $sectionContent = $(this.buttonRef.current).closest(selector);

    if ($sectionContent.length === 0) return [];

    return $sectionContent.find('.notebook-question').toArray().map(question => (
      parseInt(question.id.match(/\d+/)[0], 10)
    ));
  }

  triggerTurnInEvent() {
    window.dispatchEvent(new CustomEvent('turnIn', {
      detail: { questionIds: this.questionIds() }
    }));
  }

  getDisabledReason() {
    const key = this.props.referenceMaterialType ? this.referenceMaterialTranslationKey() : 'section';
    const model = I18n.t(key);

    if ((this.sectionTextOnly() || this.props.notebookTextOnly) && this.state.turnedIn) {
      if (this.props.referenceMaterialType) return I18n.t('turned_in_message', { model });

      return I18n.t('text_section_turned_in');
    }

    if (this.props.pastDueDate) {
      return I18n.t('due_date_passed');
    }

    if (this.sectionTextOnly() || this.props.notebookTextOnly) {
      return null;
    }

    if (this.props.singleTurnIn && this.state.turnedIn) {
      if (this.props.referenceMaterialType) return I18n.t('turned_in_message', { model });

      return I18n.t('section_turned_in');
    }

    if (this.props.notebookId && this.props.notebookTextOnly) return null;

    if (this.state.noneAnswered && !this.state.dirty) {
      return I18n.t('none_answered_msg', { model: model });
    }

    if (!this.state.dirty) {
      return I18n.t('no_questions_dirty', { model: model });
    }

    return null;
  }

  getTooltipText() {
    const key = !!this.props.referenceMaterialType ?
      this.referenceMaterialTranslationKey() :
      this.sectionTextOnly() ? 'section' : 'notebook';

    return I18n.t('turn_in_model', { model: I18n.t(key) });
  }

  handleAutosave(e) {
    if (this.questionIds().includes(e.detail.questionId)) {
      this.setState({ dirty: true });
    }
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  exerciseName() {
    const key = this.referenceMaterialTranslationKey();

    return key ? I18n.t(key) : this.props.exerciseName;
  }

  modalText() {
    let text = '';

    if (this.sectionTextOnly()) {
      return this.referenceMaterialTranslationKey() ?
        I18n.t('turn_in_confirmation', { model: this.exerciseName() }) :
        `${I18n.t('turn_in_text_only')} `;
    }

    if (SubmitNotebookButton.hasUnansweredQuestions()) {
      text += `${I18n.t('unanswered_question_label')} `;
    }

    if (this.props.singleTurnIn) {
      text += `${I18n.t('single_turn_in_label')} `;
    }
    else if (!SubmitNotebookButton.hasUnansweredQuestions()) {
      text += `${I18n.t('notebook_review_ready')} `;
    }

    text += I18n.t('turn_in_confirmation', { model: this.exerciseName() });

    return text;
  }

  headerText() {
    return I18n.t('turn_in_model', { model: this.exerciseName() });
  }

  render() {
    return (
      <Fragment>
        <Tooltip theme="white" content={capitalizeWords(this.getDisabledReason() || this.getTooltipText())}>
          <span>
            <button
              ref={this.buttonRef}
              type="button"
              className="btn btn--purple"
              onClick={this.openModal}
              disabled={!!this.getDisabledReason()}
            >
              {I18n.t('turn_in')}
            </button>
          </span>
        </Tooltip>
        <div className="mt10">
          <div>
            <SaveStatus
              saving={this.state.saving}
              saveError={this.state.saveError}
              savedTime={this.state.savedTime}
              lastUpdated={this.props.lastUpdated}
            />
          </div>
        </div>
        <Modal
          isOpen={this.state.modalOpen}
          headerText={capitalizeWords(this.headerText())}
          closeModal={this.closeModal}
          error={this.state.saveError}
        >
          {this.modalText()}

          <hr />

          <footer>
            <CancelButton onCancel={this.closeModal} />
            <SubmitButton
              onSubmit={this.submit}
              isSubmitting={this.state.saving}
              text={I18n.t('turn_in_confirmation_button')}
            />
          </footer>
        </Modal>
      </Fragment>
    );
  }
}
