import React from 'react';
import styles from '@/components/shared/LessonOverview/Cards/Cards.module.scss';
import TeacherCard from '@/components/shared/LessonOverview/Staffer/TeacherCard';
import { StafferSlideShows } from '@/types/api/LessonOverview';

interface TeacherSlideShowsProps {
  hideTabs: boolean;
  lessonId: number;
  programId: number;
  slideShows: StafferSlideShows;
  stafferId: number;
}

const TeacherSlideShows = ({
  hideTabs, lessonId, programId, slideShows, stafferId,
}: TeacherSlideShowsProps) => {
  if (slideShows.staffer.length === 0) {
    return (
      <div className={styles.placeholder} id="mySlideshowsContainer">
        <p>You haven&apos;t customized any slideshows yet.</p>
        <p>You can edit a slideshow and make it your own! Any slideshows you edit and save will show up here.</p>
      </div>
    );
  }

  return (
    <div className={styles.cards} id="mySlideshowsContainer">
      {slideShows.staffer.map(slideShow => (
        <TeacherCard
          hideTabs={hideTabs}
          key={slideShow.id}
          lessonId={lessonId}
          programId={programId}
          slideShow={slideShow}
          stafferId={stafferId}
          teacherInitials={slideShow.stafferInitials}
        />
      ))}
    </div>
  );
};

export default TeacherSlideShows;
