import React, { useMemo, useState } from 'react';
import * as Routes from '@/modules/routes';
import EditScore from '@/components/staffer/Scores/EditScore';
import ShowLessonScore from '@/components/staffer/Scores/ShowLessonScore';
import useFlags from '@/hooks/useFlags';
import Popover from '@/components/ui/Popover';
import NotebookGradeByButton from '@/components/staffer/NotebookGradeBySwitch/NotebookGradeByButton';
import useGradesTableStore from '../../store/useGradesTableStore';
import { IStudentRow } from '../../types';
import { columnHelper } from '../../variables';
import useLessonNotebooks from '../../hooks/queries/exerciseResults/useLessonNotebooks';
import { HeaderCell } from '../Cells';
import useClassroomLesson from '../../hooks/queries/useClassroomLesson';
import LessonNotebookGradeCell
  from '../Cells/GradeCells/LessonNotebookGradeCell';
import NotebookGradeByMenu from '@/components/staffer/NotebookGradeByMenu';
import { gradingByAssignment, gradingByLesson, gradingBySection } from '../../Utils';

const useLessonNotebooksColumns = () => {
  const programId = useGradesTableStore(state => state.programId);
  const classroom = useGradesTableStore(state => state.classroom);
  const lesson = useGradesTableStore(state => state.lesson);
  const { gradeByAssignment, updatedGradesTable } = useFlags();

  const { data: exerciseResults } = useLessonNotebooks();
  const { data: classroomLesson } = useClassroomLesson(classroom.id, lesson?.id);

  const [maxScore, setMaxScore] = useState(classroomLesson?.max_score);

  return useMemo(() => {
    if (gradingByAssignment(classroomLesson)) return [];

    const accessor = (row: IStudentRow) => row.student.id;

    return ([
      columnHelper.accessor(accessor, {
        header: () => (
          <HeaderCell title="Notebook" maxScore={updatedGradesTable ? maxScore : null}>
            <div className="tw-font-light">
              <Popover>
                <Popover.Trigger
                  className={`tw-cursor-pointer tw-bg-none tw-outline-none tw-border-none tw-bg-inherit tw-absolute
                                tw-right-1 tw-top-1 tw-text-lg hover:tw-bg-opacity-10 hover:tw-bg-tci-purple`}
                >
                  <i className="fa fa-ellipsis-v" />
                </Popover.Trigger>
                <Popover.Content
                  className="tw-w-auto tw-z-[1040] tw-flex tw-flex-col"
                  onOpenAutoFocus={(e) => {
                    e.preventDefault();
                    // The default focusable element is the bottom element which can be disabled
                    // and opens its tooltip which is kind of annoying behavior. Focus the always enabled el instead
                    const div = e.target as HTMLDivElement;
                    (div.children[0] as HTMLAnchorElement).focus();
                  }}
                  side="top"
                >
                  {classroomLesson && gradeByAssignment && (
                    <NotebookGradeByMenu
                      classroom={classroom}
                      classroomLesson={classroomLesson}
                      lesson={lesson}
                    />
                  )}
                  {classroomLesson && !gradeByAssignment && (
                    <NotebookGradeByButton
                      afterSubmitCallback={() => window.location.reload()}
                      classroomId={classroom.id}
                      classroomLessonId={classroomLesson.id}
                      className="dropdown-item focus:tw-bg-inherit"
                      gradingByLesson={gradingByLesson(classroomLesson)}
                      lessonId={lesson.id}
                      lessonTitle={lesson.title}
                    />
                  )}
                  {gradingByLesson(classroomLesson) && (
                    <EditScore
                      initialMaxScore={maxScore}
                      setMaxScore={setMaxScore}
                      classroomId={classroom.id}
                      lessonId={lesson.id}
                      programId={programId}
                    />
                  )}
                  {gradingBySection(classroomLesson) && !updatedGradesTable && (
                    <ShowLessonScore
                      initialMaxScore={maxScore}
                      initialSectionScores={classroomLesson.section_max_scores}
                      classroomId={classroom.id}
                      programId={programId}
                      updatedGradesTable={updatedGradesTable}
                    />
                  )}
                </Popover.Content>
              </Popover>
            </div>
            <HeaderCell.SyncGradesButton
              exerciseType="lesson notebook"
              exerciseResults={exerciseResults}
              link={Routes.sync_classroom_scores_teacher_notebook_score_path(
                classroom.id,
                {
                  classroom_id: classroom.id,
                  lesson_id: lesson.id,
                  program_id: programId
                },
              )}
            />
          </HeaderCell>
        ),
        id: 'notebook',
        cell: info => <LessonNotebookGradeCell studentId={info.getValue()} />,
      }),
    ]);
  }, [programId, classroom?.id, lesson?.id, exerciseResults, classroomLesson, maxScore]);
};

export default useLessonNotebooksColumns;
