import React from 'react';
import clsx from 'clsx';
import * as Routes from '@/modules/routes';
import eyeIcon from 'images/icons/eye.svg';
import pencilBubbleIcon from 'images/icons/pencil_bubble.svg';
import useLessonOverviewContext from '../../useLessonOverviewContext';
import ActionBar from '../ActionBar';
import CardTitle from './CardTitle';

const ICON_MAP = {
  eye: eyeIcon,
  pencil: pencilBubbleIcon,
};

interface SlideGroupCardProps {
  slideGroup: any;
  icon: 'eye' | 'pencil';
}

const SlideGroupCard = ({ icon, slideGroup }: SlideGroupCardProps) => {
  const lessonId = useLessonOverviewContext(state => state.lessonId);
  const program = useLessonOverviewContext(state => state.program);
  const unitColor = useLessonOverviewContext(state => state.unitColor);

  const presentPath = Routes.present_mode_shared_slide_show_path(slideGroup.slideShowId, {
    parent_id: lessonId,
    program_id: program.id,
    parent_type: 'Lesson',
    starting_slide: slideGroup.firstSlideId,
    from: 'lesson',
  });

  return (
    <section
      aria-label={slideGroup.title}
      className={clsx(
        'card tw-relative tw-shrink-0 tw-w-full tw-h-24 tw-m-2 tw-rounded-md',
        'tw-border-l-[0.25rem] tw-border-l-solid',
      )}
      style={{ borderLeftColor: `#${unitColor}` }}
    >
      <div className="tw-flex tw-h-full">
        <div className="tw-flex tw-items-center tw-w-24">
          <div
            className={clsx(
              'tw-flex tw-w-full tw-py-2 tw-justify-center',
              'tw-border-r tw-border-solid tw-border-r-med-grey',
            )}
          >
            <img
              aria-hidden
              alt=""
              src={ICON_MAP[icon]}
            />
          </div>
        </div>
        <div className="tw-mt-auto tw-ml-5">
          <CardTitle
            color={unitColor}
            linkHref={presentPath}
            linkLabel={slideGroup.title}
            page={slideGroup.page}
            target=""
            time={slideGroup.pacing}
          />
        </div>
        <div className="tw-ml-auto tw-my-auto">
          <ActionBar
            border={false}
            href={presentPath}
            iconType="play"
            lessonId={lessonId}
            program={program}
          />
        </div>
      </div>
    </section>
  );
};

export default SlideGroupCard;
