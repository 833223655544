import PropTypes from 'prop-types';

export const propTypes = {
  hasAssessments: PropTypes.bool.isRequired,
  program: PropTypes.shape({
    displayInvestigations: PropTypes.bool,
    displayUnitContent: PropTypes.bool,
    id: PropTypes.number.isRequired,
    middleSchool: PropTypes.bool.isRequired,
    referenceTextLabel: PropTypes.string.isRequired,
    science: PropTypes.bool.isRequired,
  }).isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({
    assignable: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    investigation: PropTypes.bool.isRequired,
    isAssigned: PropTypes.bool.isRequired,
    kind: PropTypes.string.isRequired,
    lesson: PropTypes.shape({
      assessments: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        isAssigned: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
      })),
      challenge: PropTypes.shape({
        id: PropTypes.number.isRequired,
        challengeTemplateId: PropTypes.number.isRequired,
        isAssigned: PropTypes.bool.isRequired,
      }),
      id: PropTypes.number.isRequired,
      lessonGameAssignables: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })),
      descriptionEn: PropTypes.string.isRequired,
      descriptionEs: PropTypes.string.isRequired,
    }),
    modelVideos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      video: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    })),
    notebooks: PropTypes.arrayOf(PropTypes.shape({
      fastTrack: PropTypes.bool,
      id: PropTypes.number.isRequired,
      sectionDescription: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })),
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    titleWithoutPageNumbers: PropTypes.string.isRequired,
  })).isRequired,
  slideShows: PropTypes.shape({
    assignments: PropTypes.array,
    hasStreamAssignment: PropTypes.bool,
    modules: PropTypes.array,
    staffer: PropTypes.array,
    tci: PropTypes.array,
  }).isRequired,
};
