import React, { useState } from 'react';
import { CheckboxField, SelectField } from '@/components/common/Forms';

interface LessonOverviewDisplayLocationFieldProps {
  values: any,
  setFormValue: (field: string, value: string) => void,
}
const LessonOverviewDisplayLocationField = ({ values, setFormValue }: LessonOverviewDisplayLocationFieldProps) => {
  const [isChecked, setIsChecked] = useState(!!values.display_location_in_lesson_overview);
  const [displayedLocation, setDisplayedLocation] = useState(values.display_location_in_lesson_overview);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setDisplayedLocation(null);
      setFormValue('display_location_in_lesson_overview', null);
    }

    setIsChecked(!isChecked);
  };

  const locationOptions = [
    { label: 'Introduce the Lesson', value: 'introduce_the_lesson' },
    { label: 'Lesson Options', value: 'lesson_options' },
    { label: 'Put It All Together', value: 'put_it_all_together' },
  ];

  const locationDropdownPlaceholder = (
    <div className="tw-h-full tw-text-[#7c7c7c] tw-font-normal tw-text-sm tw-leading-9">Location...</div>
  );

  return (
    <>
      <CheckboxField
        checked={isChecked}
        name="display_in_lesson_overview_toggle"
        label="Display Card on Lesson Overview Page"
        labelClass="tw-mr-2 tw-font-bold"
        tooltipText="Enabling this setting will show a card on the Lesson Overview page for this Slide Group."
        onChange={handleCheckboxChange}
      />

      {isChecked && (
        <SelectField
          className="tw-mr-auto"
          label="Card Location"
          name="display_location_in_lesson_overview"
          options={locationOptions}
          labelClass="tw-ml-8 tw-mr-2 tw-font-bold"
          fieldClass="tw-w-48"
          value={displayedLocation}
          onChange={(value: string) => setDisplayedLocation(value)}
          clearable={false}
          placeholder={locationDropdownPlaceholder}
        />
      )}
    </>
  );
};

export default LessonOverviewDisplayLocationField;
