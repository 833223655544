import React from 'react';
import * as Routes from 'modules/routes';
import I18n from 'modules/i18n';
import { Assignable, BorderColors, Lesson, Program } from '@/types/api/LessonOverview';
import gameIcon from 'images/icons/lesson-game-yellow-outline.png';
import Card, { ActionBar, CardBody } from './Card';
import AssignButton from './AssignButton';

type LessonGameCardProps = {
  bannerBorderColor: BorderColors,
  lesson: Lesson,
  lessonId: number,
  program: Program,
  stafferId?: number,
  type: string,
};

const LessonGameCard = ({
  bannerBorderColor, lesson, lessonId, program, type, stafferId,
}: LessonGameCardProps) => {
  const gameHref = (game: Assignable) => {
    const gameId = game.value.split('-')[1];

    if (game.value.includes('Challenge')) return Routes.shared_program_lesson_challenge_path(program.id, lessonId);

    return Routes.shared_program_game_path(program.id, gameId, { lesson_id: lessonId });
  };

  return (
    <Card bannerBorderColor={bannerBorderColor} label="games" type={type}>
      <CardBody
        ariaLabel={I18n.t('lesson_games')}
        iconSrc={gameIcon}
        linkHref={Routes.library_shared_program_games_path(program.id, { lesson_id: lesson.id, lesson_games: true })}
        linkLabel={I18n.t('lesson_games')}
      >
        {lesson.lessonGameAssignables.map(game => (
          <a key={game.value} href={gameHref(game)}>{game.label}</a>
        ))}
      </CardBody>
      {stafferId && (
        <ActionBar>
          <AssignButton
            initialValues={{
              modelType: 'games',
              modelsToAssign: [lesson.lessonGameAssignables[0].value],
            }}
            isAssigned={lesson.challenge.isAssigned}
            lessonId={lessonId}
            program={program}
            stafferId={stafferId}
          />
        </ActionBar>
      )}
    </Card>
  );
};

export default LessonGameCard;
