import React from 'react';
import { Lesson, Program, StudentSlideShows } from '@/types/api/LessonOverview';
import ActivityCard from '@/components/shared/LessonOverview/Cards/ActivityCard';
import TeacherCard from '@/components/shared/LessonOverview/Staffer/TeacherCard';

interface TCICreatedSlideShowCardsProps {
  lesson: Lesson;
  program: Program;
  slideShows: StudentSlideShows;
}

export const tciCreatedSlideShows = (slideShows: StudentSlideShows) => slideShows.staffer.filter((slideShow) => {
  // TCI created video activities are currently assigned through the notebook, therefore they don't show in the
  // AssignedSlideShowCards component.
  // This method can be removed once TCI slideshows have been refactored to assign the slideshow instead of notebooks.
  if (slideShow.custom) return false;

  return !(slideShow.activityType === 'video_activity' && !slideShow.isAssigned);
});

const TCICreatedSlideShowCards = ({ lesson, program, slideShows }: TCICreatedSlideShowCardsProps) => (
  <>
    {tciCreatedSlideShows(slideShows).map((slideShow) => {
      const CardComponent = slideShow.activityType ? ActivityCard : TeacherCard;

      return (
        <CardComponent
          key={slideShow.id}
          slideShow={slideShow}
          programId={program.id}
          lessonId={lesson.id} />
      );
    })}
  </>
);

export default TCICreatedSlideShowCards;
